import HomeOne from "./HomeOne";
import HoweTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import About from "./About";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import Faq from "./Faq";
import Help from "./Help";
import Login from "./Login";
import Work from "./Work";
import Error from "./Error";

import Job from "./Job";
import JobDetails from "./JobDetails";
import Policy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import RefundPolicy from "./RefundPolicy";
import GenerateVideo from "./GenerateVideo";
import VideoNew from "./VideoNew";
import Header2 from "components/header/Header2";
import Footer2 from "components/footer/footer2";
import Signup from "./Signup";
import ActivateAccount from "./ActivateAccount";
import GoogleAuth from "./GoogleAuth";
import MainForm from './MainForm'
import FormatSelection from "./FormatSelection";
import PrivateComponent from "PrivateComponent";
import FinalVideo from "./FinalVideo"
import Cookies from "js-cookie";
import ProfilePage from "./ProfilePage"
import AutoSubtitles from "./AutoSubtitles";
import BackGroundRemover from "./BackGroundRemover";
import RemoveSilence from "./RemoveSilence";
import AITools from "./AITools";
import EditVideo from "./EditVideo";
import Transcription from "./Transcription";
import ForgotPassword from './ForgotPassword';
import ResetPassword from "./ResetPassword";
import ScriptView from "./ScriptView";
import DynamicBlog from "./DynamicBlog";
import Editor from "./Editor";
import Payments from "./Payments";


const routes = [
  // { path: '/', component: <HomeOne />},
  { path: '/', component: <HoweTwo /> },
  { path: '/index-2', component: <HoweTwo /> },
  { path: '/index-3', component: <HomeThree /> },
  { path: '/about', component: <About /> },
  { path: '/blog', component: <Blog /> },
  { path: '/blog-details', component: <BlogDetails /> },
  { path: '/faq', component: <Faq /> },
  { path: '/help', component: <Help /> },
  { path: '/signup', component: <Signup /> },
  { path: '/activate/:uid/:token', component: <ActivateAccount /> },
  { path: '/google_auth', component: <GoogleAuth /> },
  { path: '/login', component: <Login /> },
  { path: '/work', component: <Work /> },
  { path: '/contact', component: <Contact /> },
  { path: '/job', component: <Job /> },
  { path: '/job-details', component: <JobDetails /> },
  { path: '*', component: <Error /> },
  { path: '/privacypolicy', component: <Policy /> },
  { path: '/termsandconditions', component: <TermsAndConditions /> },
  { path: '/refundpolicy', component: <RefundPolicy /> },
  // {path:'/generatevideo',component:<GenerateVideo/>},
  { path: '/outputvideo', component: <><VideoNew /></> },
  { path: '/result/:servicetype/:videoId', component: <FinalVideo /> },
  { path: '/generatevideo', component: <FormatSelection /> },
  { path: '/tools', component: <AITools /> },
  { path: '/script/:videoType/:videoID', component: <ScriptView /> },
  { path: '/editvideo/:videoID', component: <Editor /> },
  { path: '/transcription/:videoID', component: <Transcription /> },
  { path: '/forgotpassword', component: <ForgotPassword /> },
  { path: '/password/reset/confirm/:uid/:token', component: <ResetPassword /> },
  { path: '/blog/:blog_name', component: <DynamicBlog /> },
  { path: '/payments/:status', component: <Payments /> },

]

const protectedRoutes = [
  { path: '/text-to-video', component: <MainForm formType="t2v" /> },
  { path: '/ai-footage-editing', component: <MainForm formType="fe" /> },
  { path: '/microservices/autosubtitles', component: <AutoSubtitles /> },
  { path: '/microservices/backgroundremover', component: <BackGroundRemover /> },
  { path: '/microservices/silenceremover', component: <RemoveSilence /> },
  { path: '/profilepage', component: <ProfilePage /> },

]

export { routes, protectedRoutes };

