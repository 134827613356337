import Layout from "components/layout/Layout";
import Header2 from 'components/header/Header2';
import Footer2 from 'components/footer/footer2';
import { Link } from "react-router-dom";
import React, { useState, useRef } from 'react';
// import './Gallery/style.css';

const styles1 = `
@import url("https://fonts.googleapis.com/css2?family=Figtree&display=swap");

.containerGallery1 {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5em;
  width: 320px;
  height: 450px;
  transition: all 400ms;
}

.containerGallery1:hover .boxGallery1 {
  filter: grayscale(100%) opacity(24%);
}

.boxGallery1 {
  position: relative;
  background: var(--img) center center;
  background-size: cover;
  transition: all 400ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerGallery1 .boxGallery1:hover {
  filter: grayscale(0%) opacity(100%);
}

.containerGallery1:has(.boxGallery1-1:hover) {
  grid-template-columns: 3fr 1fr;
}

.containerGallery1:has(.boxGallery1-2:hover) {
  grid-template-columns: 1fr 3fr;
}

.boxGallery1:nth-child(odd) {
  transform: translateY(-16px);
  // filter: grayscale(80%) opacity(48%);
  }
  
  .boxGallery1:nth-child(even) {
  transform: translateY(16px);
  // filter: grayscale(80%) opacity(48%);
}

.boxGallery1::after {
  content: attr(data-text);
  position: absolute;
  bottom: 25px;
  background: #dbc4f0;
  color: #000;
  padding: 10px 10px 10px 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  transform: translateY(60px);
  opacity: 0;
  transition: all 400ms;
  font-size: 2vh;
  font-weight: 400;
  border-radius: 1em;
}

.boxGallery1:hover::after {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 400ms;
}

.containerGallery1Box{
  padding-bottom: 2vh;
}

@media only screen and (max-width: 540px){
  .containerGallery1{
    flex-wrap: wrap;
  }
  .containerGallery1Box{
    padding-bottom: 2vh;
  }
}

`;


const Gallery1 = ({src}) => {

  const videoRefs = [useRef(null), useRef(null)];

  const [isPlaying, setIsPlaying] = useState(false);

  const handleMouseOver = (videoIndex) => {
    if (!isPlaying) {
      videoRefs[videoIndex].current.play();
      setIsPlaying(true);
    }
  };
  
  const handleMouseOut = (videoIndex) => {
    if (isPlaying) {
      videoRefs[videoIndex].current.pause();
      setIsPlaying(false);
    }
  };

  const videos = [
    {src: "https://assets-editai.azureedge.net/videos-webm/1713592333_t2v_ss_1_87.webm", text: "Avengers"},
    {src: "https://assets-editai.azureedge.net/videos/1719581780_t2v_ss_1_736.mp4", text: "Volcano"}
    // {src: "https://assets-editai.azureedge.net/videos-webm/1714627993_t2v_ml_2_225.webm", text: "Elections"}
    // {src: "https://assets-editai.azureedge.net/videos/1715628281_t2v_ml_17_320.mp4", text: "Food Cravings"}
    // {src: "https://assets-editai.azureedge.net/videos-webm/1713595690_t2v_ml_3_89.webm", text: "Neural Network"}
  ]


  return (
    <>

      <div className="containerGallery1Box">
        <style>{styles1}</style>
        <div className="containerGallery1">
          {videos.map((video, index) => (
              <div className={"boxGallery1 boxGallery1-"+(index+1)} data-text={video.text} key={index}>
                <video
                  style={{ objectFit: 'cover', zIndex: -1, position: 'absolute', borderRadius: '1em'}}
                  ref={videoRefs[index]}
                  onMouseOver={() => handleMouseOver(index)}
                  onMouseOut={() => handleMouseOut(index)}
                  muted
                  loop
                  src={video.src}
                />
              </div>
            ))}
        </div>
        <Link to="/text-to-video" className="btn" >
          Text to video
        </Link>
      </div>

    </>
  );
}
export default Gallery1