import React from 'react'
import { useParams } from 'react-router-dom'

export default function Payments() {
    const params=useParams();
    const status=params.status
  return (
    <div>
        {status=="success"?<h1>Payment successfull</h1>:<h1>Payment Failed</h1>}
    </div>
  )
}
