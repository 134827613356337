import { data } from 'autoprefixer';
import axios from 'axios';
import axiosInstance from 'axiosInstance';
import VideoGallery from 'components/VideoGallery'
import Layout from 'components/layout/Layout'
import GenerateVideo from 'pages/GenerateVideo';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Tabs } from "flowbite-react";
import { HiAdjustments, HiClipboardList, HiUserCircle, HiFilm, HiArchive } from "react-icons/hi";
import { RiAiGenerate } from "react-icons/ri"; // <RiAiGenerate />
import { RiDraftFill } from "react-icons/ri"; // <RiDraftFill />

export default function ProfilePage() {

  const [name, setName] = useState("");
  const [credits, setCredits] = useState(0);
  const [videos, setVideos] = useState([]);
  const [completedVideos, setCompletedVideos] = useState([]);
  const [draftVideos, setDraftVideos] = useState([])
  const [email, setEmail] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Generated");

  const fetchUserInfo = async () => {
    try {
      let response = await axiosInstance.get(process.env.REACT_APP_BACKEND_URL + "/getuserinfo/", { headers: { "Content-Type": "application/json" } });
      data = response.data;
      console.log(data)
      setName(data.first_name + " " + data.last_name);
      setCredits(data.credits+data.purchased_credits);
      setEmail(data.email);

    }
    catch (e) {
      console.log(e);
    }

  };

  const fetchUserVideos = async () => {
    try {
      let response = await axiosInstance.get(process.env.REACT_APP_BACKEND_URL + "/getgeneratedvideos/", { headers: { "Content-Type": "application/json" } });
      data = response.data;
      console.log(data);
      let completedVideos = data.filter(video => video.completion == true);
      let nonCompletedVideos = data.filter(video => video.completion == false);
      setCompletedVideos(completedVideos);
      setDraftVideos(nonCompletedVideos);

    }
    catch (e) {
      console.log(e);
    }
  };

  const handleCategoryChange = (type) => {
    setSelectedCategory(type);
  };

  useEffect(() => {
    fetchUserInfo();
    fetchUserVideos();
  }, [])

  return (
    <Layout mainCls="main-content fix" headerStyle={2} footerStyle={2}>
      <div className="px-16 pb-16">
        <div className="p-8 bg-white shadow-2xl rounded-3xl mt-24">
          <div className="grid grid-cols-1 md:grid-cols-3">
            <div className="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">
              {/* <div>
        <p className="font-bold text-gray-700 text-xl">22</p>
        <p className="text-gray-400">Friends</p>
      </div>
      <div>
           <p className="font-bold text-gray-700 text-xl">10</p>
        <p className="text-gray-400">Photos</p>
      </div>
          <div>
           <p className="font-bold text-gray-700 text-xl">89</p>
        <p className="text-gray-400">Comments</p>
      </div> */}
            </div>
            <div className="relative">
              <div className="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
                <img src={`https://avatar.iran.liara.run/public/boy?username=${name}`} />
                {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                </svg> */}
              </div>
            </div>

            <div className="space-x-8 flex justify-between mt-32 md:mt-0 md:justify-center">
              <button
                className="text-white py-2 px-4 uppercase rounded bg-blue-400 hover:bg-blue-500 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
              >
                <Link to="/generatevideo">Create Video</Link>
              </button>
              <button
                className="text-white py-2 px-4 uppercase rounded bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
              >
                <Link to="/#pricing">Update Plan</Link>
              </button>
            </div>
          </div>

          <div className="mt-10 text-center pb-12">
            <h1 className="text-4xl font-medium text-gray-700">{name}</h1>
            {/* <p className="font-light text-gray-600 mt-3">Bucharest, Romania</p> */}

            {/* <p className="mt-8 text-gray-500"><b>Your Subscription:</b> Free Trial</p> */}
            <p className="mt-2 text-gray-500">
              <b >Credits Remaining: </b> {credits}
            </p>
            <p className="mt-2 text-gray-400 font-sans">
              1 credit = 1 second of video generation
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <div className="pt-2">
              <Tabs aria-label="Video Tabs" variant="default" className="w-full flex items-center justify-center">
                <Tabs.Item
                  active
                  title="&nbsp; Generated"
                  icon={HiFilm}
                >
                  <VideoGallery videos={completedVideos} />
                </Tabs.Item>
                <Tabs.Item
                  title="&nbsp; Drafts"
                  icon={HiArchive}
                >
                  <VideoGallery videos={draftVideos} />

                  {/* <VideoGallery videos={draftVideos} /> */}
                </Tabs.Item>
              </Tabs>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}
