import React, { useState } from 'react'
import Header2 from 'components/header/Header2';
import Footer2 from 'components/footer/footer2';
import { FileInput, Label, Checkbox } from 'flowbite-react';
import styles from "./style.module.css"
import axiosInstance from 'axiosInstance';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/customComponents/Loader';
import { Player } from '@lottiefiles/react-lottie-player';
import { validateAndCorrectFileName, validateDuration, validateFileSize, validateFileType } from 'utils';


export default function AutoSubtitles() {

    const [video,setVideo]=useState();
    const [subtitle, setSubtitle] = useState("HORMOZI");
    const subtitleList = [ 'HORMOZI', 'maya', 'NOAH', 'william', 'IMAN','BEAST', 'DAVID','glowy']
    const [subtitleType, setSubtitleType] = useState('line');
    const [orientation,setOrientation]=useState('portrait');
    const [subtitlePosition,setSubtitlePosition]=useState("bottom");
    const navigate=useNavigate();

    const [loadingState,setLoadingState]=useState(false)
    const videoGenerationLoadingLottie="https://lottie.host/deae5640-9508-420d-ba9f-a18f11cde781/EYovAyAMKT.json"

    const addSubtitles=async()=>{

      if (!validateFileType(video)) {
        alert('Invalid file type. Please upload a video file.');
        return;
      }
    
      if (!validateFileSize(video)) {
        alert('File size exceeds the limit. Please upload a smaller file.');
        return;
      }
    
      const isDurationValid = await validateDuration(video);
      if (!isDurationValid) {
        alert('Video duration exceeds the limit. Please upload a shorter video.');
        return;
      }
  
      const validFileName=validateAndCorrectFileName(video);
      const correctedFile = new File([video], validFileName, { type: video.type });
      setLoadingState(true);
      const formData= new FormData();
      formData.append('input_video', correctedFile);
      formData.append('subtitle_name', subtitle);
      formData.append('subtitle_position', subtitlePosition);
      formData.append('orientation', orientation);
      formData.append('subtitle_type', subtitleType);
      formData.append('service_type', "autosubtitles"); 

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      const response=await axiosInstance.post(process.env.REACT_APP_BACKEND_URL+'/microservices/autosubtitles/',formData,config);
      setLoadingState(false);
      console.log(response.data)
      if (response.data.suceess == 0) {
        return alert(response.data.message);
      }

      console.log(response.data);
      const id = response.data.id;
  

      navigate(`/result/microservices/${id}`);
      console.log(response);
    }

  return (
    <div>
      <Header2/>

      <div className={loadingState?styles.animation:styles.hidden}>
          <Player className={styles.loadingAnimation}
            src={videoGenerationLoadingLottie} 
            loop
            autoplay
          />
          <h1 className={styles.LoadingText}>Your video is in the making! 📼</h1>
        </div>
        <div className={loadingState?styles.hidden:styles.mainContainer}>
        <div className={styles.descriptionBox}>
          <div className={styles.descriptionContent}>
          <div className={styles.descriptionText}>
            <h2><strong>Auto Trendy Subtitles 😎</strong></h2>
            <p>Automatically generate <strong>trendy</strong> and <strong>engaging</strong> subtitles for your videos. Just upload your video and let Edit AI do the rest.</p>
            <p>Enhance your content with subtitles that capture attention and increase viewer engagement.</p>
            </div>
            <video controls className={styles.demoVideo} >
              <source src="https://assets-editai.azureedge.net/videos/1716218951_Subtitle_Micro_Grgo2j_nitabg.mp4" type="video/mp4" loop autoPlay muted />
              Your browser does not support the video tag.
            </video>
          </div>       
          
        </div>


        <br></br>
        <br></br>


        <div className={styles.descriptionBox}>
          <div className={styles.descriptionContent}>
          <div className={styles.descriptionText}>
            <h2><strong>How to get Auto Trendy Subtitles? 🤔&nbsp;</strong></h2>
            <strong>Upload your video<br></br></strong>
            <p>Click on <strong>'Click to upload'</strong> and upload your video.</p>
            <br></br>
            <br></br>
            <strong>Choose subtitle type<br></br></strong>
            <p>Select the type of subtitle that is most suitable for your video and click on it to proceed.</p>
            <br></br>
            <br></br>
            <strong>Submit your video<br></br></strong>
            <p>Next, select the <strong>subtitle category</strong> as either single line or single word. Then, choose your uploaded <strong>video orientation</strong> as either portrait or landscape. Finally, select the <strong>subtitle position</strong> as either bottom or center. Once it’s done, let the magic happen and see your captions generated</p>
            </div>
            <div className={styles.demoImageContainer}>
            <img src="/assets/ai-tools-assets/auto_subtitle_img.png" alt="Demo" className={styles.demoImage} />
            </div>
          </div>       
          
        </div>


        <h5 className="my-3">Upload video</h5>
        <div className="flex w-full items-center justify-center my-3">
          <Label
            htmlFor="dropzone-file"
            className="dark:hover:bg-bray-800 flex h-64  cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5 pr-20 pl-20">
              <svg
                className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLineJoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                {video ? <span className="font-semibold">{video.name}</span> : <span className="font-semibold">Click to upload</span>}
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">.mp4 format only</p>
            </div>
            <FileInput id="dropzone-file" className="hidden" onChange={(e) => { setVideo(e.target.files[0]) }} />
          </Label>
        </div>
        <div className={styles.subtitleContainer}>
        <p>Choose subtitle type</p>
          <div className={styles.subtitles}>
            {subtitleList.map((sub) => (
              <video autoplay
                onClick={() => setSubtitle(sub)}
                  src={subtitleType === 'word' ? `https://assets-editai.azureedge.net/videos-subtitles/${sub}_an_portim.mp4` : `/assets/demovideos/subtitles/${sub}.mp4`}
                className={subtitle === sub ? styles.selected : ''}
                loop muted autoPlay
              ></video>))}
          </div>
        </div>
        <div className={styles.options}>
        <div className="buttons py-1 mx-2">
          <p>Subtitle category</p>
          <button
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitleType === 'line'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              setSubtitleType('line')

            }}
          >
            Single line
          </button>
          <button
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitleType === 'word'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              setSubtitleType('word')
            }}
          >
            Single word
          </button>


          
        </div>
        <div className="buttons py-1 mx-2">
          <p>Video orientation</p>
          <button
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${orientation === 'portrait'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              setOrientation('portrait')
            }}
          >
            Portrait
          </button>


          <button
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${orientation === 'landscape'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              setOrientation('landscape')

            }}
          >
            Lanscape
          </button>
        </div>
        <div className="buttons py-1 mx-2">
          <p>Subtitle Position</p>
          <button
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitlePosition === 'bottom'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              setSubtitlePosition('bottom')
            }}
          >
            Bottom
          </button>


          <button
            className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitlePosition === 'centre'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {
              setSubtitlePosition('centre')

            }}
          >
            Centre
          </button>
        </div>
        </div>
      <button type="button" class=" my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={()=>addSubtitles()}>Submit</button>

        </div>
        <Footer2/>
    </div>
  )
}
