import Layout from "components/layout/Layout";
import { Link } from "react-router-dom";
import CountUp, { useCountUp } from "react-countup";

import GroupPhoto from "../photos/HCHC.jpg";
import Asif from "../photos/asif_new_copy.jpg";
import Jadhav from "../photos/jadhav.jpg";
import Mundlik from "../photos/mundlik_new.jpg";
import Mohit from "../photos/Mohti.jpg";
import mustansir from "../photos/mustansir_new.jpg";
export default function About() {
  return (
    <Layout
      headerStyle={2}
      footerStyle={2}
      breadcrumbTitle={
        <div>
          About <span>Edit AI</span>
        </div>
      }
    >
      <div>
        {/* about-area */}
        <section className="inner-about-area pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-about-img">
                  <img src="/assets/img/team/about_us_team.jpg" alt="" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="inner-about-content">
                  <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                    Content Creation and Automated Video Editing with AI
                  </h2>
                  <div className="content-bottom">
                    <p>
                      At Edit AI , we use artificial intelligence to generate
                      high-quality content at scale. Our platform analyzes vast
                      amounts of data using natural language processing and
                      machine learning algorithms to create accurate, compelling
                      content. Whether you need product descriptions, social
                      media posts, or blog articles, our AI-powered solutions
                      save time, reduce costs, and deliver measurable results.
                      Let us show you how our technology can transform your
                      content strategy and help you achieve your business goals.
                    </p>
                    <ul className="list-wrap">
                      <li>
                        <i className="far fa-check" />
                        Automated Content Creation
                      </li>
                      <li>
                        <i className="far fa-check" />
                        We Focus on Quantity Well Organised Content.
                      </li>
                      <li>
                        <i className="far fa-check" />
                        Multilingual Support
                      </li>
                      <li>
                        <i className="far fa-check" />
                        Team of 5 Students
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* about-area-end */}
        {/* team-area */}
        <section className="team-area pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-four text-center mb-60">
                  <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                    Core Members
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src={Mohit} alt="" height={580} width={580} />
                    <div className="team-hidden-content">
                      <h4 className="title">Mohit Chawla</h4>
                      <p>
                        Mohit Chawla is an enthusiastic AI and Automation
                        developer from India, currently working on Editai, an
                        innovative AI-powered video editor. With a keen interest
                        in advancing his skills, he is actively learning
                        PyTorch. Mohit regularly shares his insights and
                        projects, which are showcased on his portfolio. He is
                        passionate about Python web development and automation,
                        and is open to discussing these topics. To learn more
                        about his experiences, you can contact him at
                        chawlamohit45@gmail.com.
                      </p>
                    </div>
                  </div>
                  <div className="team-content">
                    <div className="team-info">
                      <h4 className="title">Mohit Chawla</h4>
                      <span>
                        Co-Founder / CEO (Chief Executive Officer) / Research
                        And Development
                      </span>
                    </div>
                    <div className="team-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="https://x.com/chawlamohit1313?t=xLJW8k9ZjDHjoGhWqgO1Vw&s=09">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.instagram.com/chawlamohit1313?igsh=dHd0bnFjcGIxd3g3">
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.linkedin.com/in/mohit-chawla13">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src={Asif} alt="" height={580} width={580} />
                    <div className="team-hidden-content">
                      <h4 className="title">Asif Mursal</h4>
                      <p>
                        Asif Mursal is set to graduate in 2025. Currently
                        serving as the Web Development Co-head at Team Quark,
                        Asif specializes in cutting-edge technologies such as
                        JavaScript, React, Node, Python, and Django. With a
                        passion for exploring new technologies, Asif is
                        dedicated to crafting seamless web experiences and is
                        eager to connect and innovate for a future filled with
                        endless possibilities.
                      </p>
                    </div>
                  </div>
                  <div className="team-content">
                    <div className="team-info">
                      <h4 className="title">Asif Mursal</h4>
                      <span>
                        Co-Founder / COO(Chief Operations Officer) / Backend
                        Developer
                      </span>
                    </div>
                    <div className="team-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="https://twitter.com/asif_mursal_01">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://github.com/AsifMursal">
                            <i className="fab fa-github" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.linkedin.com/in/asif-mursal/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.instagram.com/asif_mursal_01/">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src={mustansir} alt="" height={580} width={580} />
                    <div className="team-hidden-content">
                      <h4 className="title">Mustansir Bohari</h4>
                      <p>
                        Mustansir Bohari is a final year AI & Data Science
                        student with a passion for new technologies. Proficient
                        in web development using Flask and MERN, as well as web3
                        development and building AI workflows, Mustansir
                        actively participates in hackathons. With a confident
                        and decisive problem-solving approach and a
                        collaborative attitude, he is eager to learn through
                        hands-on experience and contribute to open-source
                        projects.
                      </p>
                    </div>
                  </div>
                  <div className="team-content">
                    <div className="team-info">
                      <h4 className="title">Mustansir Bohari</h4>
                      <span>
                        Co-Founder / CTO(Chief Technology Officer) / DevOps
                        Engineer
                      </span>
                    </div>
                    <div className="team-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="https://twitter.com/MustansirBohari">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://github.com/B-Mustansir">
                            <i className="fab fa-github" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.linkedin.com/in/bohari-mustansir/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src={Mundlik} alt="" height={580} width={580} />
                    <div className="team-hidden-content">
                      <h4 className="title">Omkar Mundlik</h4>
                      <p>
                        Omkar Mundlik is a dedicated final year student at
                        Vishwakarma Institute of Technology in Pune, currently
                        involved in building Edit AI. His technical skills
                        encompass web development with React.js and Django, and
                        he has gained valuable experience working at YUJ VIT
                        Pune. Omkar's passion for technology and problem-solving
                        is evident in his active participation in hackathons and
                        collaborative projects.
                      </p>
                    </div>
                  </div>
                  <div className="team-content">
                    <div className="team-info">
                      <h4 className="title">Omkar Mundlik</h4>
                      <span>
                        Co-Founder / CPO(Chief Product Officer) / Backend
                        Developer
                      </span>
                    </div>
                    <div className="team-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="https://twitter.com/Omkar_Mundlik_">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.instagram.com/omkar.mundlik_/">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.linkedin.com/in/omkar-mundlik-a86389237/">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://github.com/OmkarMundlik">
                            <i className="fab fa-github" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="team-item">
                  <div className="team-thumb">
                    <img src={Jadhav} alt="" height={580} width={580} />
                    <div className="team-hidden-content">
                      <h4 className="title">Omkar Jadhav</h4>
                      <p>
                        Omkar Jadhav, a student from Mumbai, India, is pursuing
                        a Bachelor of Technology in Artificial Intelligence and
                        Data Science at Vishwakarma Institute of Technology,
                        Pune. Proficient in multiple
                        programming languages and frameworks, he has also held
                        leadership roles, including AI/ML Co-Head at Google
                        Developer Student’s Club VIT Pune and AI/ML Team Member
                        at Team Quark.
                      </p>
                    </div>
                  </div>
                  <div className="team-content">
                    <div className="team-info">
                      <h4 className="title">Omkar Jadhav</h4>
                      <span>
                        Co-Founder / CCO(Chief Customers Officer) / Market
                        Analyst
                      </span>
                    </div>
                    <div className="team-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="https://twitter.com/OMKARRAJESH2">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://github.com/O-m-k-a-r">
                            <i className="fab fa-github" />
                          </Link>
                        </li>
                        <li>
                          <Link to="http://www.linkedin.com/in/omkar-jadhav-0736b0234">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-10">
                <div className="team-item-two">
                  <div className="team-thumb-two">
                    <img src="assets/img/team/team_img03.jpg" alt="" />
                  </div>
                  <div className="team-content-two">
                    <div className="team-info">
                      <h4 className="title">Anastasia Yatsun</h4>
                      <span>Software Engineer</span>
                    </div>
                    <div className="team-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-10">
                <div className="team-item-two">
                  <div className="team-thumb-two">
                    <img src="assets/img/team/team_img04.jpg" alt="" />
                  </div>
                  <div className="team-content-two">
                    <div className="team-info">
                      <h4 className="title">Ben Miller</h4>
                      <span>UI/UX Designer</span>
                    </div>
                    <div className="team-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-10">
                <div className="team-item-two">
                  <div className="team-thumb-two">
                    <img src="assets/img/team/team_img05.jpg" alt="" />
                  </div>
                  <div className="team-content-two">
                    <div className="team-info">
                      <h4 className="title">Rob Lubow</h4>
                      <span>QA Engineer</span>
                    </div>
                    <div className="team-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* team-area-end */}
        {/* counter-area */}
        {/* <section className="counter-area-three pb-160">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="counter-content">
                  <div className="section-title-four mb-40">
                    <h2 className="title wow fadeInLeft" data-wow-delay=".2s">
                      AI can write content just like humans can
                    </h2>
                  </div>
                  <p>
                    By using Natural Language Processing (NLP) techniques, AI
                    can understand the context, tone, and intent of a given
                    piece of content, and produce written output that's relevant
                    and engaging. This technology is especially useful for
                    generating large volumes of content quickly and accurately,
                    which can save businesses a significant amount of time and
                    resources.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="counter-item-wrap-three">
                  <ul className="list-wrap">
                    <li>
                      <div className="counter-item-three">
                        <h2 className="count">
                          <CountUp
                            end={1}
                            suffix="M"
                            duration={5}
                            enableScrollSpy
                            scrollSpyDelay={5}
                          />
                        </h2>
                        <p>Our Top Clients</p>
                      </div>
                    </li>
                    <li>
                      <div className="counter-item-three">
                        <h2 className="count">
                          <CountUp
                            end={50}
                            suffix="M"
                            duration={5}
                            enableScrollSpy
                            scrollSpyDelay={5}
                          />
                        </h2>
                        <p>Social Media Platforms</p>
                      </div>
                    </li>
                    <li>
                      <div className="counter-item-three">
                        <h2 className="count">
                          <CountUp
                            end={4}
                            suffix="K"
                            duration={5}
                            enableScrollSpy
                            scrollSpyDelay={5}
                          />
                        </h2>
                        <p>Powerful AI Tools</p>
                      </div>
                    </li>
                    <li>
                      <div className="counter-item-three">
                        <h2 className="count">
                          <CountUp
                            end={10}
                            suffix="M"
                            duration={5}
                            enableScrollSpy
                            scrollSpyDelay={5}
                          />
                        </h2>
                        <p>Our Readers</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* counter-area-end */}
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-3xl wow fadeInUp" data-wow-delay=".2s">
            Sponsored By: <span className="text-blue-500">Microsoft for Startups Founders Hub</span>
          </h2>
          <p className="flex flex-col text-lg pt-6">
              <div>Name: <b>edictai</b></div>
              <div>Address: <b>60/3A Bhagyeshree Bangalow, Sai Nagar Lane No.: 3</b> </div>
              <div>Contact Information: <b>mustansirzain2@gmail.com</b> and <b>988-121-0225</b></div>
              <div>Domain of the organization: <b>Media and Content Creation</b></div>
          </p>
        </div>
      </div>
    </Layout>
  );
}
