// import React from "react";
// import { Link } from "react-router-dom";

// const VideoGallery = ({ videos }) => {
//     return (
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//             {videos.map((video, index) => (
//                 <div key={index} className="relative">
//                     <Link to={video.src} target="_blank">
//                         {/* <iframe
//                             className="m-auto rounded-lg"
//                             src={video.src}
//                             title={video.title}
//                             allowFullScreen
//                             height={16 * 3 * 9}
//                             width={144 * 1.5}
//                         ></iframe> */}
//                         <p className="text-black text-sm truncate">Title: {video.title}</p>
//                         <p className="text-black text-sm truncate">Prompt: {video.prompt}</p>
//                         <p className="text-black text-sm truncate">Type: {video.type}</p>

//                     </Link>
                    
//                 </div>
//             ))}
//         </div>
//     );
// };

// export default VideoGallery;


import React from "react";
import { Link } from "react-router-dom";

const VideoGallery = ({ videos }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {videos.map((video, index) => (
                <div key={index} className="relative border rounded-lg shadow-md">
                    <Link to={video.completion?`/result/mainservices/${video.hash_id}`:
                            video.type=="t2v"?`/script/${video.layering_type}/${video.hash_id}`:
                            `/transcription/${video.hash_id}`
                        } 
                            
                            target="_blank" className="block hover:bg-blue-200 hover:rounded-lg ease-in-out">
                        {/* <div className="aspect-w-16 aspect-h-9">
                            <img src={video.thumbnail} alt={video.title} className="object-cover w-full h-full" />
                        </div> */}
                        <div className="p-4">
                            <p className="flex items-start text-black text-sm mb-2">Title: &nbsp; {video.name}</p>
                            <p className="flex items-start text-gray-600 text-sm mb-2">Id: &nbsp; <b>{video.hash_id}</b> </p>
                            <p className="flex items-start text-gray-600 text-sm">Type: &nbsp; <div className="uppercase">{video.type}</div> </p>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default VideoGallery;
