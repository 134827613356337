import { Link } from "react-router-dom";




const Services2 = () => {
  return (
    <section className="services-area-two pt-140 pb-125">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-10">
            <div
              className="section-title-two text-center mb-70 title-animation wow fadeInUp"
              data-wow-delay=".2s"
            >
              <h1 className="title">
                EDIT AI <span>Tools Showcase</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
         
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="services-item-two">
              <div className="services-item-overlay" />
              <div className="services-icon-two">
                <img src="/assets/img/icon/t2v_icon.png" alt="" />
              </div>
              <div className="services-content-two">
                <h2 className="title">
                  <Link to="/text-to-video">Text-to-video (Multilayered)</Link>
                </h2>
                <p>Convert prompt or content to full video in a click!</p>
              </div>
            </div>
          </div>
          
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="services-item-two">
              <div className="services-item-overlay" />
              <div className="services-icon-two">
                <img src="/assets/img/icon/t2v_slideshow_icon.png" alt="" />
              </div>
              <div className="services-content-two">
                <h2 className="title">
                  <Link to="/text-to-video">Text to video (slideshow)</Link>
                </h2>
                <p>Convert prompt or content to slideshow in a click!</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="services-item-two">
              <div className="services-item-overlay" />
              <div className="services-icon-two">
                <img src="/assets/img/icon/fe_icon.png" alt="" />
              </div>
              <div className="services-content-two">
                <h2 className="title">
                  <Link to="/ai-footage-editing">
                    Footage Editing (Multilayered)
                  </Link>
                </h2>
                <p>Provide raw footage and get fully edited footage!</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="services-item-two">
              <div className="services-item-overlay" />
              <div className="services-icon-two">
                <img src="/assets/img/icon/fe_2.png" alt="" />
              </div>
              <div className="services-content-two">
                <h2 className="title">
                  <Link to="/ai-footage-editing">Footage Editing (slideshow)</Link>
                </h2>
                <p>Provide raw footage and get fully edited footage</p>
              </div>
            </div>
          </div>
          <div
              className="section-title-two text-center mt-35 mb-35 title-animation wow fadeInUp"
              data-wow-delay=".2s"
            >
              <h2 className="title">
                Free to use<span>Tools</span>
              </h2>
            </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="services-item-two">
              <div className="services-item-overlay" />
              <div className="services-icon-two">
                <img src="/assets/img/icon/subtitle.png" alt="" />
              </div>
              <div className="services-content-two">
                <h2 className="title">
                  <Link to="/microservices/autosubtitles">Auto Trendy😎 Subtitles/Captions</Link>
                </h2>
                <p>Add trendy subtitles to video in a click!</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="services-item-two">
              <div className="services-item-overlay" />
              <div className="services-icon-two">
                <img src="/assets/img/icon/bg_remover.png" alt="" />
              </div>
              
              <div className="services-content-two">
                
                <h2 className="title">
                  <Link to="/microservices/backgroundremover">Video Background Removal</Link>
                </h2>
                
                <p>Remove Background from any video for FREE!</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="services-item-two">
              <div className="services-item-overlay" />
              <div className="services-icon-two">
                <img src="/assets/img/icon/silence_removal.png" alt="" />
              </div>
              <div className="services-content-two">
                <h2 className="title">
                  <Link to="/microservices/silenceremover">Auto Silence Removal</Link>
                </h2>
                <p>Cut awkward silences from video in a click!</p>
              </div>
            </div>
          </div>
        </div>
        </div>

      
      <div className="services-shape-two">
        <img
          src="/assets/img/images/h3_services_shape.png"
          alt=""
          className="wow fadeInRight"
          data-wow-delay=".2s"
        />
      </div>
    </section>
  );
};
export default Services2;
