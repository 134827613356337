import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';

const EditorBox = ({ chunkIndex, videoJson, setVideoJson, videoLink }) => {
  const [layerIndex, setLayerIndex] = useState(0);

  useEffect(() => {
    setLayerIndex(0);
  }, [chunkIndex])

  const deleteLayer = (chunkIndex, layerIndex) => {
    if (!window.confirm("Delte layer")) {
      return
    }
    let copyVideoJson = [...videoJson];

    copyVideoJson[chunkIndex].layers[layerIndex].selected_asset = "";
    copyVideoJson[chunkIndex].layers[layerIndex].background_path = "";

    setVideoJson(copyVideoJson);
  }

  const selectAsset = (chunkIndex, layerIndex, url, urlIndex) => {
    let copyVideoJson = [...videoJson];

    // let prevSelectedUrl = copyVideoJson[chunkIndex].layers[layerIndex].selected_asset;
    // copyVideoJson[chunkIndex].layers[layerIndex].alternative_assets[urlIndex]=prevSelectedUrl
    copyVideoJson[chunkIndex].layers[layerIndex].selected_asset = url;

    setVideoJson(copyVideoJson);
  }

  const handlePrevLayer = () => {
    setLayerIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNextLayer = () => {
    setLayerIndex((prev) => Math.min(prev + 1, videoJson[chunkIndex].layers.length - 1));
  };

  const uploadFile = async (file) => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    let containerName = '';
    if (file.type.startsWith('image/')) {
      containerName = 'image-container';
    } else if (file.type.startsWith('video/')) {
      containerName = 'custom-video-container';
    } else {
      alert('File type not supported. Please upload an image or video.');
      return;
    }
    console.log(containerName);

    const sasToken = process.env.REACT_APP_AZURE_BLOB_SAS_TOKEN;
    const storageAccountName = process.env.REACT_APP_AZURE_BLOB_ACCOUNT;

    const blobServiceClient = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // Create a unique name for the blob
    const blobName = new Date().getTime() + '-' + file.name;
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    console.log(blockBlobClient);

    try {
      await blockBlobClient.uploadBrowserData(file, {
        blobHTTPHeaders: { blobContentType: file.type },
      });
      const url = blockBlobClient.url.split('?')[0]; // Get the URL of the uploaded file

      alert('File uploaded successfully!');
      return url; // Return the file URL
    } catch (error) {
      console.error('Upload failed:', error.message);
      alert('Upload failed!');
      return null;
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    const url = await uploadFile(file);
    console.log(url);
    let copyVideoJson = [...videoJson];
    copyVideoJson[chunkIndex].layers[layerIndex].selected_asset = url;
    setVideoJson(copyVideoJson);
  };

  const renderMainAsset = () => {
    if (videoJson[chunkIndex].layers.length === 0) {
      return <div className="text-center text-gray-500">No assets available</div>;
    }

    const option = videoJson[chunkIndex].layers[layerIndex].selected_asset || null;
    if (option === null) {
      return <h1 className="text-center text-gray-500"> No asset selected</h1>
    }
    const isVideo = option.endsWith('.mp4');

    if (isVideo) {
      return <video src={option} className="max-w-full max-h-full object-contain rounded-lg" controls />;
    } else {
      return <img src={option} alt="Main Asset" className="max-w-full max-h-full object-contain rounded-lg" />;
    }
  };

  const renderOptionalAssets = () => {

    const options = videoJson[chunkIndex].layers[layerIndex].alternative_assets || null;
    if (options === null) {
      return <h1 className="text-center text-gray-500">No assets available</h1>
    }
    return options.map((option, index) => {
      const isVideo = option.endsWith('.mp4');
      const isSelected = videoJson[chunkIndex].layers[layerIndex].selected_asset === option;
      return (
        <div key={index}
          className={`${isSelected ? 'p-1 border-2 border-slate-400 rounded-lg' : ''}`}
          onClick={() => {
            selectAsset(chunkIndex, layerIndex, option, index)
          }}>
          {isVideo ? (
            <video src={option} className="w-full h-32 object-cover rounded-md" style={{ aspectRatio: '2 / 1' }} />
          ) : (
            <img src={option} alt={`Option ${index + 1}`} className="w-full h-32 object-cover rounded-md" style={{ aspectRatio: '2 / 1' }} />
          )}
        </div>
      );
    });
  };

  return (
    <div className="flex md:h-screen">

      <div className="flex md:flex-row flex-col flex-1 p-4 pb-0 rounded-md">

        <div className="md:w-1/4 border border-slate-400 p-4 rounded-md flex flex-col font-plusJakarta overflow-hidden">

          <div className="mb-3 text-center">
            <span className="font-outfit text-2xl">Transcription</span>
          </div>

          <div className="flex flex-col h-full overflow-hidden">
            <div
              className="flex-3 p-4 pt-3 bg-slate-100 rounded-3xl mb-3 overflow-y-auto h-32 font-sans text-slate-800">
              {videoJson[chunkIndex].subtitle_data.chunk}
            </div>

            <div className="mb-3 text-center">
              <span className="font-outfit text-2xl">Video</span>
            </div>

            <div className="flex-1 flex items-center justify-center overflow-hidden mb-2">
              <video src={videoLink} className="max-w-full max-h-full object-contain rounded-xl" controls />
            </div>

          </div>
        </div>

        <div className="flex-1 border border-slate-400 p-4 pt-2 rounded-md ml-1 flex flex-col">

          <div className="flex items-center justify-between my-2">
            <div className="p-2 rounded-lg">
              <span className="font-outfit text-2xl">
                Layer {layerIndex + 1}/{videoJson[chunkIndex].layers.length}
              </span>
            </div>

            <div className="flex space-x-2">
              <button
                className="text-white bg-slate-700 hover:bg-slate-900 focus:ring-4 focus:ring-gray-300 font-outfit font-light rounded-full text-lg px-4 py-2 cursor-pointer"
                onClick={handlePrevLayer}
                disabled={layerIndex === 0}
              >
                Prev
              </button>
              <button
                className="text-white bg-slate-700 hover:bg-slate-900 focus:ring-4 focus:ring-gray-300 font-outfit font-light rounded-full text-lg px-4 py-2 cursor-pointer"
                onClick={handleNextLayer}
                disabled={layerIndex === videoJson[chunkIndex].layers.length - 1}
              >
                Next
              </button>
            </div>
          </div>

          {(videoJson[chunkIndex].layers[layerIndex] && videoJson[chunkIndex].layers[layerIndex].selected_asset !== "") ?
            <>
              <div className="flex flex-1 overflow-hidden">

                <div className="md:w-1/3 p-2 overflow-y-auto scrollbar-style-8 flex flex-col space-y-4">
                  {renderOptionalAssets()}
                </div>

                <div className="hidden md:flex flex-1 px-4 items-center justify-center overflow-hidden">
                  <div className="w-full h-full flex items-center justify-center">
                    {renderMainAsset()}
                  </div>
                </div>
              </div>

              <div className="flex justify-between mt-4 gap-3">
                <button
                  className="text-white text-center bg-red-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-outfit font-light rounded-full text-base md:text-lg px-4 py-2.5 cursor-pointer"
                  onClick={() => deleteLayer(chunkIndex, layerIndex)}
                >
                  Delete Layer
                </button>
                <label
                  className="text-white text-center bg-green-500 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-outfit font-light rounded-full text-base md:text-lg px-4 py-2.5 cursor-pointer"
                >
                  Custom Upload
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                </label>
              </div>
            </>
            :
            null
          }
        </div>

      </div>

    </div>
  );
};

export default EditorBox;