
import Header2 from "components/header/Header2"
import DataBg from "components/layout/DataBg"
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";


export default function Login() {
    const [email, setEmail] = useState();
    
    const navigate=useNavigate();
    const handlesubmit = async () => {
        try {
            if (!email) {
                return alert("fill all the fields")
            }
            const data = { email }
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/users/reset_password/', JSON.stringify(data), { headers: { "content-type": "application/json" } });
            console.log(response);
            alert('Please check your mail for reset link');
            // navigate('/');
        }
        catch (err) {
            console.log(err);
            if (err.response.status == 401) {
                alert(err.response.data.detail);
            }
        }
    }

    const googleLogin = async () => {
        console.log("google")
        try {
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"

                }
            }
            axios.defaults.withCredentials = true;
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL+"/auth/o/google-oauth2/?redirect_uri=https://editai.in/google_auth/", config);
            console.log(response)
            window.location = response.data.authorization_url;
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <DataBg />
            <Header2 />
            <main className="main-content">
                <div className="noise-bg" data-background="/assets/img/bg/noise_bg.png" />
                <div className="main-shape" data-background="/assets/img/images/main_shape.png" />
                <section className="login-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-10 pb-12">
                                <div className="login-content ms-6">
                                    
                                    <span>👋 Please enter your details. To get reset link🔗.</span>
                                    

                                    <form onSubmit={(e) => { e.preventDefault(); handlesubmit() }}>
                                        <div className="form-grp">
                                            <label htmlFor="email">Your Email</label>
                                            <input type="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                        </div>
                    

                                        <button type="submit" className="sine-btn">Submit</button>
        

                                    </form>
                                    
                                    
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="login-right-content-wrap">
                                    <div className="login-right-bg" data-background="assets/img/bg/error_bg.jpg" />
                                    <div className="login-right-content-inner">
                                        <img src="assets/img/images/loginpageImg.png" alt="" />
                                        <h4 className="title">Revolutionize Your Content: Try <span>Edit AI Today</span> and watch your content soar</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    )
}