import Layout from "components/layout/Layout";
import ActivateBanner from "components/layout/banner/ActivateBanner";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const ActivateAccount = () => {

  const params=useParams();
  const navigate=useNavigate();
  const activate=async()=>{
    let data = JSON.stringify({
      "uid": params.uid,
      "token": params.token
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BACKEND_URL+'/auth/users/activation/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      alert("Your account has been activated");
      navigate('/login');
    })
    .catch((error) => {
      console.log(error);
    });
  }
  return (
    <Layout mainCls="main-content fix" headerStyle={2} footerStyle={2}>

      <ActivateBanner activate={activate} />
      
    </Layout>
  );
};

export default ActivateAccount;
