import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";


export default function GoogleAuth() {
    const params=useParams();
    const navigate=useNavigate();
    const loginViaGoogle=async()=>{
        try{
            // Get the current URL
var currentUrl = window.location.href;

// Extract the values of state and code using regular expressions
var stateMatch = currentUrl.match(/state=([^&]+)/);
var codeMatch = currentUrl.match(/code=([^&]+)/);

// Check if the matches are found
if (stateMatch && codeMatch) {
    var state = stateMatch[1];
    var code = codeMatch[1];

    // Now you can use the extracted values (state and code) as needed
    console.log('State:', state);
    console.log('Code:', code);
} else {
    console.error('State or code not found in the URL');
}            
const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Cookie":`sessionid=${Cookies.get('sessionid')}`
      }
      
    }
            axios.defaults.withCredentials = true;
            const response=await axios.post(process.env.REACT_APP_BACKEND_URL+`/auth/o/google-oauth2/?state=${state}&code=${code}`,config)
            console.log(response);
            try {
                // Decode the JWT token
                const decodedToken = jwtDecode(response.data.refresh);
                const decodedAccessToken = jwtDecode(response.data.access);

                
                // Get the expiry date from the token
                const expiryDate = new Date(decodedToken.exp * 1000);
                const accessExpiry=new Date(decodedAccessToken.exp *1000);
                // Check if the token is already expired
                if (expiryDate < new Date()) {
                    console.error("The token has already expired.");
                    return;
                }
                
                // Set a cookie that expires on the token's expiry date
                Cookies.set('refreshToken', response.data.refresh, { expires: expiryDate });
                Cookies.set('accessToken', response.data.access, { expires: accessExpiry });
                
                console.log(`Cookie set to expire on ${expiryDate}`);
            } catch (error) {
                console.error("Error decoding token or setting cookie:", error);
            }
            navigate('/');
        }
        catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        loginViaGoogle();
    },[])
  return (
    <div>
      
    </div>
  )
}
