import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { Analytics } from "@vercel/analytics/react";
import ReactGA from "react-ga4";
import { SpeedInsights } from "@vercel/speed-insights/react";

ReactGA.initialize("G-80MY71203S");
ReactGA.send({ 
  hitType: "pageview", 
  page: window.location.pathname
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop/>
    <App />
    <Analytics/>
    <SpeedInsights/>
  </BrowserRouter>,
);

