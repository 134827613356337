import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function PrivateComponent() {
    const auth = Cookies.get('refreshToken');
    const location = useLocation();

    return (
        auth
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}
