import axiosInstance from 'axiosInstance';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Header2 from 'components/header/Header2';
import Footer2 from 'components/footer/footer2';
import styles from './style.module.css';

import { Player } from '@lottiefiles/react-lottie-player';

export default function Transcription() {

    const params = useParams();
    const [wordJSON, setWordJSON] = useState([]);
    const [transcription, setTranscription] = useState("");
    const [windowMode, setWindowMode] = useState("view");
    const [loadingState, setLoadingState] = useState(false);

    const videoGenerationLoadingLottie = "https://lottie.host/deae5640-9508-420d-ba9f-a18f11cde781/EYovAyAMKT.json"
    const navigate = useNavigate()

    const generateVideo = async () => {
        setLoadingState(true);
        try {    
            const data = {
                video_id: params.videoID
            }
            let response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/editfootage/`, data);          
            console.log(response);
            if (response.data.success == 0) {
                alert(response.data.message);
                setLoadingState(false);
                return; // Prevent further execution
            }
            const id = response.data.id;
            setLoadingState(false);
            navigate(`/result/mainservices/${id}`);
        } catch (error) {
            console.error(error);
            setLoadingState(false);
        }
    }

    const fetchTranscription = async (id) => {
        try {
            let response = await axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/gettranscription/${id}`);
            if (response.data.success == 0) {
                return alert(response.data.message)
            }
            console.log(response.data)
            setWordJSON(response.data.wordJson)
            let para = createParagraph(response.data.wordJson);
            setTranscription(para);
        }
        catch (e) {
            console.log(e);
        }
    }

    const saveTranscript = async () => {
        try {
            const data = {
                wordJSON, video_id: params.videoID
            }
            let response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/edittranscription/`, data);
            console.log(response);
            if (response.data.success === 1) {
                setWindowMode("view");
            }
            else {
                return alert(response.data.message)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    function createParagraph(wordsArray) {
        return wordsArray.map(wordObj => wordObj.word).join(' ');
    }

    useEffect(() => {
        const id = params.videoID;
        fetchTranscription(id);
    }, [])

    const handleInputChange = (index, event) => {
        const newWordJSON = [...wordJSON];
        newWordJSON[index].word = event.target.value;
        setWordJSON(newWordJSON);

        // Update transcription with the new words
        let para = createParagraph(newWordJSON);
        setTranscription(para);
    };

    return (
        <div>
            <Header2 />
            <div className={loadingState ? styles.animation : styles.hidden}>
                <Player className={styles.loadingAnimation}
                    src={videoGenerationLoadingLottie}
                    loop
                    autoplay
                />
                <h1 className={styles.LoadingText}> Hold tight! EditAI is creating your video. You'll get an email with the link soon!</h1>
            </div>
            <div className={loadingState ? styles.hidden : styles.mainContainer}>
                {windowMode === "view" ?
                    <>
                        <div className={styles.para}>
                            <h1>Transcription</h1>{transcription}
                        </div>
                        <div className={styles.buttonsDiv}>
                            <button className='inline-block rounded border border-indigo-600 px-12 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500 ml-4' onClick={() => setWindowMode("edit")}>Edit</button>
                            <button className='inline-block rounded border border-indigo-600 px-12 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500 ml-4' onClick={() => generateVideo()}>Submit</button>
                        </div>
                    </>
                    : null}
            </div>
            <div className={loadingState ? styles.hidden : styles.editContainer}>
                {windowMode === "edit" ? <>

                    <div className={styles.editOptions}>
                        {wordJSON.map((word, index) => {
                            return <input className={styles.editOption} key={index} value={word.word} onChange={(e) => handleInputChange(index, e)}></input>
                        })}
                    </div>

                    <div className={styles.buttonsDiv}>
                        <button className='inline-block rounded border border-indigo-600 px-12 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500 ml-4' onClick={() => setWindowMode("view")}>Cancel</button>
                        <button className='inline-block rounded border border-indigo-600 px-12 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500 ml-4' onClick={() => saveTranscript()}>Save</button>
                    </div>
                </> : null}

            </div>
            <Footer2 />
        </div>
    )
}
