import Layout from "components/layout/Layout";

const Texts = ({ heading, texts }) => {
    return (
        <div className="flex flex-col w-2/3">
            <h5 className="text-neutral-600 font-semibold text-justify mt-2 mb-2 wow fadeup" data-wow-delay=".2s">
                {heading}
            </h5>
            <ol className="list-decimal text-neutral-500 text-justify pl-5 wow fadeInUp mb-3" data-wow-delay=".2s">
                {texts.map((text, index) => (
                    <li key={index} className="mb-2">
                        {text}
                    </li>
                ))}
            </ol>
        </div>
    );
};

const Heads = () => {
    return (
        <section className="banner-area-three p-0">
            <div className="banner-shape bg-white opacity-100" data-background="assets/img/banner/h3_banner_shape.png" />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-9 col-lg-10">
                        <div className="banner-content-three text-center flex flex-col align-middle items-center">
                            <h2 className="text-slate-700 text-7xl my-4 font-extrabold wow fadeInUp" data-wow-delay=".2s">
                                Privacy Policy
                            </h2>
                            <b className="font-mono text-slate-400">Last updated: 31st August 2024</b>
                            <p className="text-black text-center wow fadeInUp mt-4" data-wow-delay=".4s">
                                Thank you for choosing Edit AI, an AI-powered multi-layered video editor. <br />
                                This Privacy Policy outlines how we collect, use, disclose, and protect your information
                                in connection with our services. <br />
                                By using Edit AI, you agree to the terms outlined in this policy.
                            </p>

                            <h3 className="text-neutral-400 text-5xl my-4 font-bold wow fadeInUp" data-wow-delay=".2s">
                                <b>Information We Collect</b>
                            </h3>

                            {/* Information We Collect Section */}
                            <Texts
                                heading="User-Provided Information:"
                                texts={[
                                    "Usage Data: We collect information about your interactions with Edit AI, including log files, IP address, device information, and usage statistics. This helps us improve our service and provide a better user experience.",
                                    "Technical Information: Edit AI utilizes advanced algorithms to edit videos, and while processing content, technical information about the editing process may be collected for optimization and troubleshooting purposes."
                                ]}
                            />

                            {/* How We Use Your Information Section */}
                            <Texts
                                heading="How We Use Your Information"
                                texts={[
                                    "Video Editing Services: We use the information you provide to process and edit videos as per your instructions, including the integration of graphics, icons, b-rolls, transitions, and SFX to create multi-layered videos.",
                                    "Service Improvement: Data collected, both user-provided and automatically collected, helps us enhance and optimize the performance, features, and overall user experience of Edit AI."
                                ]}
                            />

                            {/* Information Sharing Section */}
                            <Texts
                                heading="Information Sharing"
                                texts={[
                                    "Edit AI does not share your personal information or edited content with third parties, except as required for the provision of our services or as outlined in this Privacy Policy."
                                ]}
                            />

                            {/* Data Security Section */}
                            <Texts
                                heading="Data Security"
                                texts={[
                                    "We employ industry-standard security measures to protect your information. However, it's important to note that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security."
                                ]}
                            />

                            {/* User Control Section */}
                            <Texts
                                heading="User Control"
                                texts={[
                                    "Account Information: You can review and update your account information through the account settings.",
                                    "Content Ownership: You retain ownership of the content you provide for video editing. Edit AI does not claim any ownership rights over the edited videos."
                                ]}
                            />

                            {/* Third-Party Integration Section */}
                            <Texts
                                heading="Third-Party Integration"
                                texts={[
                                    "Edit AI may integrate with third-party services for specific features. Please review the privacy policies of these third-party services, as they may have their own terms and practices."
                                ]}
                            />

                            {/* Changes to Privacy Policy Section */}
                            <Texts
                                heading="Changes to this Privacy Policy"
                                texts={[
                                    "We may update this Privacy Policy to reflect changes in our services or applicable laws. Please check this page for any updates. Your continued use of Edit AI after any changes indicates your acceptance of the updated terms."
                                ]}
                            />

                            {/* Contact Us Section */}
                            <Texts
                                heading="Contact Us"
                                texts={[
                                    <span key="contact">
                                        If you have any questions or concerns about this Privacy Policy or Edict AI's practices, please contact us at {' '}
                                        <a href="mailto:team@editai.in" className="text-blue-600 underline">
                                            team@editai.in
                                        </a>.
                                    </span>
                                ]}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

// Main Policy Component
const Policy = () => {
    return (
        <Layout mainCls="main-content-two fix" headerStyle={2} footerStyle={2}>
            <Heads />
        </Layout>
    );
};

export default Policy;