import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axiosInstance from "axiosInstance";
import styles from "../pricing/style.module.css";

const Pricing2 = () => {

  const [isToggled, setToggled] = useState(false);
  const toggleTrueFalse = () => setToggled(!isToggled);
  const navigate = useNavigate();
  const [isCombo, setIsCombo] = useState(false);
  const [videoCount, setVideoCount] = useState(1);


  const togglePricing = () => setIsCombo(!isCombo);

  function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

  const makepayment=async(amount)=>{

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
  }
    try{
        let plan=isCombo?"bulk":"basic"
        console.log(amount,plan)
        const data={
          amount,
          plan
        }
        const response=await axiosInstance.post(process.env.REACT_APP_BACKEND_URL+`/payments/create-order/`,data);
        console.log(response)
        const order_id=response.data.order_id
        const user_info=response.data.user_info
        var options = {
          "key": process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
          "amount": response.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": "INR",
          "name": "Edit AI",
          "description": "Premium plan",
          "image": "https://www.editai.in/assets/img/logo/logo_big.png",
          "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          "callback_url": `${process.env.REACT_APP_BACKEND_URL}/payments/razorpay-callback`,
          "prefill": {
              "name": user_info.name,
              "email": user_info.email,
          },
          "notes": {
              "address": "editai.in"
          },
          "theme": {
              "color": "#5850ec"
          }
      };
      var razor = new window.Razorpay(options);
      console.log(razor);
      razor.open();

    }
    catch (e) {
      console.log(e);
    }
  }

  // Pricing details
  const singlePrice = 119; // Price per video
  const comboPrice = 1199; // Price for 12 videos

  return (
    <section className="pricing-area-two">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div id="pricing_scroll" className="section-title-two text-center mb-55">
              <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                Plans for&nbsp;
                <span>
                  <strong>
                    AI video
                    <svg
                      viewBox="0 0 345 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M344.366 0.00527191C231.51 16.2892 117.803 18.0623 5.82257 7.9456C3.59842 7.74418 0.292572 9.4891 0.0174561 12.1809C-0.260559 14.9221 2.83823 17.0748 5.06818 17.301C117.89 28.79 231.159 22.6085 344.545 1.4609C345.266 1.32606 345.074 -0.0971813 344.366 0.00527191Z"
                        fill="url(#title-06)"
                      />
                      <defs>
                        <linearGradient
                          id="title-06"
                          x1="376.061"
                          y1="0.000463246"
                          x2="10.2776"
                          y2="101.79"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0.0361276" stopColor="#FAEC60" />
                          <stop offset="0.159651" stopColor="#E5A34B" />
                          <stop offset="0.269837" stopColor="#EE6E4D" />
                          <stop offset="0.42316" stopColor="#F44380" />
                          <stop offset="0.55979" stopColor="#BE3DB3" />
                          <stop offset="0.745252" stopColor="#7746E6" />
                          <stop offset="0.888441" stopColor="#5A71F1" />
                          <stop offset={1} stopColor="#439EFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </strong>
                  &nbsp;content &nbsp;
                </span>
                creation strategy
              </h2>
            </div>
          </div>
        </div>
        <div className="pricing-item-wrap-two">
          <div className="pricing-billing-duration text-center">
            <div className="pricing-tab" onClick={togglePricing}>
              <span className={`tab-btn ${!isCombo ? 'active' : ''}`}>Pay-As-You-Go</span>
              <span
                className={isCombo ? "pricing-tab-switcher active" : "pricing-tab-switcher"}
              />
              <span className={`tab-btn ${isCombo ? 'active' : ''}`}>Bundle</span>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div
                className="pricing-item-two wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <div className="pricing-shape">
                  <svg
                    viewBox="0 0 410 610"
                    fill="none"
                    x="0px"
                    y="0px"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M30 0C13.4315 0 0 13.4315 0 30V159.153C0.651939 159.052 1.31987 159 2 159C8.14716 159 13.2978 163.267 14.6521 169H20.3V172H15C15 179.18 9.1797 185 2 185C1.31987 185 0.651939 184.948 0 184.847V580C0 596.569 13.4315 610 30 610H380C396.569 610 410 596.569 410 580V184.847C409.348 184.948 408.68 185 408 185C400.82 185 395 179.18 395 172C395 164.82 400.82 159 408 159C408.68 159 409.348 159.052 410 159.153V30C410 13.4315 396.569 0 380 0H30ZM28.42 172H36.54V169H28.42V172ZM44.66 172H52.78V169H44.66V172ZM60.9 172H69.02V169H60.9V172ZM77.14 172H85.26V169H77.14V172ZM93.38 172H101.5V169H93.38V172ZM109.62 172H117.74V169H109.62V172ZM125.86 172H133.98V169H125.86V172ZM142.1 172H150.22V169H142.1V172ZM158.34 172H166.46V169H158.34V172ZM174.58 172H182.7V169H174.58V172ZM190.82 172H198.94V169H190.82V172ZM207.06 172H215.18V169H207.06V172ZM223.3 172H231.42V169H223.3V172ZM239.54 172H247.66V169H239.54V172ZM255.78 172H263.9V169H255.78V172ZM272.02 172H280.14V169H272.02V172ZM288.26 172H296.38V169H288.26V172ZM304.5 172H312.62V169H304.5V172ZM320.74 172H328.86V169H320.74V172ZM336.98 172H345.1V169H336.98V172ZM353.22 172H361.34V169H353.22V172ZM369.46 172H377.58V169H369.46V172ZM385.7 172H393.82V169H385.7V172Z"
                      fill="currentcolor"
                    />
                  </svg>
                </div>
                <div className="pricing-top-content">
                  <h2 className="pricing-plan">Free</h2>
                  <div className="pricing-price">
                    <h2 className="price monthly_price">
                      6
                      <span>videos</span>
                      {/* <span>/ {isToggled ? "year" : "month"}</span> */}
                    </h2>
                  </div>
                </div>
                <div className="price-ordering">
                  {/* <select name="orderby" className="orderby">
                    <option value="Default sorting">
                      01 video minutes/Month
                    </option>
                    <option value="Sort by popularity">
                      01 video minutes/Year
                    </option>
                  </select> */}
                </div>
                <div className="pricing-list">
                  <ul className="list-wrap">
                    <li>Text-to-video</li>
                    <li>Footage editing</li>
                    <li>Edit AI tools ( 1 credit = 1 second )</li>
                    <li className="delete">No Watermark</li>
                    <li className="delete">custom Media Upload</li>
                    {/* <li className="delete">Brand Assets Addition</li> */}
                    {/* <li className="delete">20+ Remove background/mo</li> */}
                  </ul>
                </div>
                <div className="pricing-btn">
                  <button className="gradient-btn gradient-btn-three" onClick={() => makepayment(10)}>
                    choose plan
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <div
                className="pricing-item-two active wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="pricing-shape">
                  <svg
                    viewBox="0 0 410 610"
                    fill="none"
                    x="0px"
                    y="0px"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M30 0C13.4315 0 0 13.4315 0 30V159.153C0.651939 159.052 1.31987 159 2 159C8.14716 159 13.2978 163.267 14.6521 169H20.3V172H15C15 179.18 9.1797 185 2 185C1.31987 185 0.651939 184.948 0 184.847V580C0 596.569 13.4315 610 30 610H380C396.569 610 410 596.569 410 580V184.847C409.348 184.948 408.68 185 408 185C400.82 185 395 179.18 395 172C395 164.82 400.82 159 408 159C408.68 159 409.348 159.052 410 159.153V30C410 13.4315 396.569 0 380 0H30ZM28.42 172H36.54V169H28.42V172ZM44.66 172H52.78V169H44.66V172ZM60.9 172H69.02V169H60.9V172ZM77.14 172H85.26V169H77.14V172ZM93.38 172H101.5V169H93.38V172ZM109.62 172H117.74V169H109.62V172ZM125.86 172H133.98V169H125.86V172ZM142.1 172H150.22V169H142.1V172ZM158.34 172H166.46V169H158.34V172ZM174.58 172H182.7V169H174.58V172ZM190.82 172H198.94V169H190.82V172ZM207.06 172H215.18V169H207.06V172ZM223.3 172H231.42V169H223.3V172ZM239.54 172H247.66V169H239.54V172ZM255.78 172H263.9V169H255.78V172ZM272.02 172H280.14V169H272.02V172ZM288.26 172H296.38V169H288.26V172ZM304.5 172H312.62V169H304.5V172ZM320.74 172H328.86V169H320.74V172ZM336.98 172H345.1V169H336.98V172ZM353.22 172H361.34V169H353.22V172ZM369.46 172H377.58V169H369.46V172ZM385.7 172H393.82V169H385.7V172Z"
                      fill="currentcolor"
                    />
                  </svg>
                </div>
                <div className="pricing-top-content">
                  <h2 className="pricing-plan">Basic</h2>
                  <div className="pricing-price">
                    <h2 className="price monthly_price">
                      ₹{isToggled ? "219" : "299"}{" "}
                      <span>/{isToggled ? " 600 seconds" : " 600 seconds"}</span>
                    </h2>
                  </div>
                </div>
                <div className="price-ordering">
                  <select name="orderby" className="orderby">
                    <option value="Default sorting">
                      15 video minutes/Month
                    </option>
                    <option value="Sort by popularity">
                      15 video minutes/Year
                    </option>
                  </select>
                </div>
                <div className="pricing-list">
                  <ul className="list-wrap">
                    <li>Text-to-video</li>
                    <li>Footage editing</li>
                    <li>Edit AI tools (Unlimited)</li>
                    <li>No Watermark</li>
                    <li className="delete">Custom Media Upload (Max 5 for a video)</li>
                    <li className="delete">Brand Assets</li>
                  </ul>
                </div>
                <div className="pricing-btn">
                  <button className="btn btn-three" onClick={() => makepayment(isToggled ? 2628 : 299)}>
                    choose plan
                  </button>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6">
              <div
                className="pricing-item-two active wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="pricing-shape">
                  <svg
                    viewBox="0 0 410 610"
                    fill="none"
                    x="0px"
                    y="0px"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M30 0C13.4315 0 0 13.4315 0 30V159.153C0.651939 159.052 1.31987 159 2 159C8.14716 159 13.2978 163.267 14.6521 169H20.3V172H15C15 179.18 9.1797 185 2 185C1.31987 185 0.651939 184.948 0 184.847V580C0 596.569 13.4315 610 30 610H380C396.569 610 410 596.569 410 580V184.847C409.348 184.948 408.68 185 408 185C400.82 185 395 179.18 395 172C395 164.82 400.82 159 408 159C408.68 159 409.348 159.052 410 159.153V30C410 13.4315 396.569 0 380 0H30ZM28.42 172H36.54V169H28.42V172ZM44.66 172H52.78V169H44.66V172ZM60.9 172H69.02V169H60.9V172ZM77.14 172H85.26V169H77.14V172ZM93.38 172H101.5V169H93.38V172ZM109.62 172H117.74V169H109.62V172ZM125.86 172H133.98V169H125.86V172ZM142.1 172H150.22V169H142.1V172ZM158.34 172H166.46V169H158.34V172ZM174.58 172H182.7V169H174.58V172ZM190.82 172H198.94V169H190.82V172ZM207.06 172H215.18V169H207.06V172ZM223.3 172H231.42V169H223.3V172ZM239.54 172H247.66V169H239.54V172ZM255.78 172H263.9V169H255.78V172ZM272.02 172H280.14V169H272.02V172ZM288.26 172H296.38V169H288.26V172ZM304.5 172H312.62V169H304.5V172ZM320.74 172H328.86V169H320.74V172ZM336.98 172H345.1V169H336.98V172ZM353.22 172H361.34V169H353.22V172ZM369.46 172H377.58V169H369.46V172ZM385.7 172H393.82V169H385.7V172Z"
                      fill="currentcolor"
                    />
                  </svg>
                </div>
                <div className="pricing-top-content">
                  <h2 className="pricing-plan">Basic</h2>
                  <div className="pricing-price">
                    <h2 className="price monthly_price">
                      {isCombo ? `₹1199` : `₹${(singlePrice * videoCount)}`}{" "}
                      <span>{isCombo ? "/ 12 videos" : `/ ${videoCount} video${videoCount > 1 ? 's' : ''}`}</span>
                    </h2>
                    <div className="pt-2 text-xs text-gray-400">
                      {/* ${videoCount} video = Number(45*{videoCount}) seconds */}
                      {isCombo ? `${12 * 45} seconds of video`: `${videoCount * 45} seconds of video`}
                    </div>
                  </div>
                </div>
                {isCombo ? null : (
                  <div className={styles.sliderContainer}>
                    <div className={styles.sliderWrapper}>
                      <input
                        type="range"
                        min="1"
                        max="9"
                        value={videoCount}
                        onChange={(e) => setVideoCount(Number(e.target.value))}
                        className={styles.slider}
                        id="videoSlider"
                      />
                      <div className={styles.sliderLabels}>
                        <span>1</span>
                        <span>2</span>
                        <span>3</span>
                        <span>4</span>
                        <span>5</span>
                        <span>6</span>
                        <span>7</span>
                        <span>8</span>
                        <span>9</span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="pricing-list">
                  <ul className="list-wrap">
                    <li>Text-to-video</li>
                    <li>Footage editing</li>
                    <li>Edit AI tools (Unlimited)</li>
                    <li>No Watermark</li>
                    {/* <li className="delete">Custom Media Upload (Max 5 for a video)</li> */}
                    <li className="delete">Custom Media Upload</li>
                    <li className="delete">Brand Assets</li>
                  </ul>
                </div>
                <div className="pricing-btn">
                  <button className="btn btn-three" onClick={() => makepayment(isCombo ? 1199 : (singlePrice * videoCount))}>
                    Choose Plan
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="pricing-item-two wow fadeInRight"
                data-wow-delay=".2s"
              >
                <div className="sticker">
                  <img src="/assets/img/images/sticker.png" alt="" />
                </div>
                <div className="pricing-shape">
                  <svg
                    viewBox="0 0 410 610"
                    fill="none"
                    x="0px"
                    y="0px"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M30 0C13.4315 0 0 13.4315 0 30V159.153C0.651939 159.052 1.31987 159 2 159C8.14716 159 13.2978 163.267 14.6521 169H20.3V172H15C15 179.18 9.1797 185 2 185C1.31987 185 0.651939 184.948 0 184.847V580C0 596.569 13.4315 610 30 610H380C396.569 610 410 596.569 410 580V184.847C409.348 184.948 408.68 185 408 185C400.82 185 395 179.18 395 172C395 164.82 400.82 159 408 159C408.68 159 409.348 159.052 410 159.153V30C410 13.4315 396.569 0 380 0H30ZM28.42 172H36.54V169H28.42V172ZM44.66 172H52.78V169H44.66V172ZM60.9 172H69.02V169H60.9V172ZM77.14 172H85.26V169H77.14V172ZM93.38 172H101.5V169H93.38V172ZM109.62 172H117.74V169H109.62V172ZM125.86 172H133.98V169H125.86V172ZM142.1 172H150.22V169H142.1V172ZM158.34 172H166.46V169H158.34V172ZM174.58 172H182.7V169H174.58V172ZM190.82 172H198.94V169H190.82V172ZM207.06 172H215.18V169H207.06V172ZM223.3 172H231.42V169H223.3V172ZM239.54 172H247.66V169H239.54V172ZM255.78 172H263.9V169H255.78V172ZM272.02 172H280.14V169H272.02V172ZM288.26 172H296.38V169H288.26V172ZM304.5 172H312.62V169H304.5V172ZM320.74 172H328.86V169H320.74V172ZM336.98 172H345.1V169H336.98V172ZM353.22 172H361.34V169H353.22V172ZM369.46 172H377.58V169H369.46V172ZM385.7 172H393.82V169H385.7V172Z"
                      fill="currentcolor"
                    />
                  </svg>
                </div>
                <div className="pricing-top-content">
                  <h2 className="pricing-plan">Advanced</h2>
                  <div className="pricing-price">
                    <h2 className="price monthly_price">
                      {/* ₹{isToggled ? "399" : "499"}{" "}
                       */}
                      Enterpise
                      {/* <span>/{isToggled ? " 1200 credits" : " 1200 credits"}</span> */}
                    </h2>
                  </div>
                </div>
                <div className="price-ordering">
                  {/* <select name="orderby" className="orderby">
                    <option value="Default sorting">
                      1 video minute/ 60 credits 
                    </option>
                    <option value="Sort by popularity">
                      1 video minute/ 60 credits
                    </option>
                  </select> */}
                </div>
                <div className="pricing-list">
                  <ul className="list-wrap">
                    <li>Includes all basic plan features</li>

                    {/* <li>Edit AI tools</li> */}

                    <li>Custom Media Upload</li>
                    <li>Brand Assets</li>
                    <li>Video customisations</li>
                    <li>Automated workflows</li>
                  </ul>
                </div>
                <div className="pricing-btn">
                  <Link to="mailto:team@gmail.com" className="btn btn-three"> Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing-shape-wrap">
        <img
          src="/assets/img/images/pricing_shape.png"
          alt=""
          className="alltuchtopdown"
        />
      </div>
    </section>
  );
};
export default Pricing2;
