import axios from 'axios';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import dayjs from 'dayjs';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 60 * 1000*60,
});

// Request interceptor
axiosInstance.interceptors.request.use(async (req) => {
  let accessToken = Cookies.get("accessToken");
  let refreshToken = Cookies.get("refreshToken");

  if (!accessToken) {
    const response = await refreshTokenFunction(refreshToken);
    if (response) {
      accessToken = response;
    }
  } else {
    const user = jwtDecode(accessToken);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (isExpired) {
      const response = await refreshTokenFunction(refreshToken);
      if (response) {
        accessToken = response;
      }
    }
  }

  if (accessToken) {
    req.headers.Authorization = "JWT " + accessToken;
  }

  return req;
}, error => {
  return Promise.reject(error);
});

// Function to refresh token
const refreshTokenFunction = async (refreshToken) => {
  try {
    const response = await axios.post(`${baseURL}/auth/jwt/refresh/`, JSON.stringify({ refresh: String(refreshToken) }), { headers: { 'Content-Type': 'application/json' } });
    const newAccessToken = response.data.access;
    const decodedToken = jwtDecode(newAccessToken);
    const expiryDate = new Date(decodedToken.exp * 1000);

    if (expiryDate < new Date()) {
      console.error("The token has already expired.");
      return null;
    }

    Cookies.set('accessToken', newAccessToken, { expires: expiryDate });
    return newAccessToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return null;
  }
};

// Response interceptor (Optional, uncomment if needed)
// axiosInstance.interceptors.response.use(
//   response => {
//     return response;
//   },
//   function (error) {
//     const originalRequest = error.config;

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const refreshToken = Cookies.get('refreshToken');
//       return refreshTokenFunction(refreshToken).then(newAccessToken => {
//         if (newAccessToken) {
//           axios.defaults.headers.common['Authorization'] = 'JWT ' + newAccessToken;
//           originalRequest.headers['Authorization'] = 'JWT ' + newAccessToken;
//           return axios(originalRequest);
//         }
//       });
//     }

//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
