import axiosInstance from 'axiosInstance';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Header2 from 'components/header/Header2';
import Footer2 from 'components/footer/footer2';
import styles from './styles.module.css';
import style from './style.module.css'
import { driver } from 'driver.js';
import "driver.js/dist/driver.css";

import { TextAnalysisClient, AzureKeyCredential } from '@azure/ai-language-text'
import { Player } from '@lottiefiles/react-lottie-player';
import { Avatar } from 'flowbite-react';
import { MdNavigateNext } from "react-icons/md";
import audioAnimationGif from './sound_wave_22.gif'

export default function ScriptView() {
    const [currentWindow, setCurrentWindow] = useState(0);
    const params = useParams();
    const videoType = params.videoType;
    const videoId = params.videoID;
    const [generatedScript, setgeneratedScript] = useState({});
    const [script, setScript] = useState("");
    const [windowMode, setWindowMode] = useState("view");
    const [loadingState, setLoadingState] = useState(false);
    const [scriptChangePrompt, setScriptChangePrompt] = useState("");
    const [subtitle, setSubtitle] = useState("NOAH");
    const [audios, setAudios] = useState([]);
    const [selectedAudio, setSelectedAudio] = useState("en-US-AvaMultilingualNeural");
    const [subtitleType, setSubtitleType] = useState('line');
    const [progress, setProgress] = useState(0)
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [playingAudio, setPlayingAudio] = useState(null);
    const [languageCode, setLanguageCode] = useState('en');
    const [backgroundType, setBackGroundType] = useState('1OYekmbneyPNbCcTmBfierwjEFvuzoIh-');

    // const subtitleList = ['HORMOZI', 'maya', 'NOAH', 'william', 'IMAN', 'BEAST', 'DAVID']
    const subtitleList = ['glowy', 'maya', 'NOAH', 'william', 'IMAN', 'BEAST']
    const cloudinaryLinks = [
        'https://assets-editai.azureedge.net/videos/1720163139_grid_bbunak.mp4',
        'https://assets-editai.azureedge.net/videos/1716219709_Black_Topography_Lvqmw7_jpkdgr.mp4',
        'https://assets-editai.azureedge.net/videos/1716219461_Arcane_Inferno_M12ogz_bgbqcw.mp4',
        'https://assets-editai.azureedge.net/videos/1705241131_Red_Flame_mbaarr.mp4',
        'https://assets-editai.azureedge.net/videos/1705241132_Black_Paper_Texture_vhnhlx.mp4'
    ]
    const backs = [
        '1OYekmbneyPNbCcTmBfierwjEFvuzoIh-',
        '1Qh2mrlrGe_ft5G2Toc3JzF9gp6ceCPLb',
        '1k2whu9cknBLdORrG2doWzRR22DcHQhVU',
        '1cqe2KoBmI9BePprwa6XC9xvtkoeX_5To',
        '1zioxvIbSQ80gWLd0TLpe0OWYeVZpE8XN'
    ]

    const videoGenerationLoadingLottie = "https://lottie.host/deae5640-9508-420d-ba9f-a18f11cde781/EYovAyAMKT.json"
    const navigate = useNavigate()

    const fetchAudios = async () => {
        const response = await axiosInstance.get(process.env.REACT_APP_BACKEND_URL + '/getaudios/', { headers: { "Content-Type": "application/json" } });
        console.log(response);
        setAudios(response.data);
    }

    const getVideo = async () => {
        setButtonDisabled(true);
        window.scrollTo(0, 0);
        setLoadingState(true);

        const data = {
            video_id: videoId,
            main_type: videoType,
            background: backgroundType,
            subtitle_type: subtitleType,
            subtitle_name: subtitle,
            selected_audio: selectedAudio,
            orientation: "portrait"
        }

        try {
            const config = {
                headers: { "content-type": "application/json" }
            }
            const response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/generatevideo/', data, config);
            if (response.data.success == 0) {
                alert(response.data.message);
                return
            }

            setButtonDisabled(false);
            setLoadingState(false);
            console.log(response.data);
            navigate(`/result/mainservices/${response.data.id}`);
        }
        catch (e) {
            console.log(e);
            setLoadingState(false);
            setWindowMode("view");
            setButtonDisabled(false);
        }

    }

    const fetchScript = async (id) => {
        try {
            let response = await axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/getgeneratedscript/${id}`);
            if (response.data.success == 0) {
                return alert(response.data.message)
            }
            console.log(response.data)
            setgeneratedScript(response.data.script_json)
        }
        catch (e) {
            console.log(e);
        }
    }

    const saveScript = async () => {
        try {
            const data = {
                video_id: params.videoID,
                script_json: generatedScript
            }
            let response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/editscript/`, data);
            console.log(response);
            if (response.data.success === 1) {
                setWindowMode("view");
            }
            else {
                return alert(response.data.message)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const handleAudioPlay = (audioId) => {
        if (playingAudio && playingAudio !== audioId) {
            const prevAudio = document.getElementById(playingAudio);
            prevAudio.pause();
        }
        setPlayingAudio(audioId);
    };

    const handleAudioPause = () => {
        setPlayingAudio(null);
    };

    const detectLanguage = async (text) => {
        try{
            const client = new TextAnalysisClient(process.env.REACT_APP_AZURE_SPEECH_SERVICE_LANGUAGE_ENDPOINT, new AzureKeyCredential(process.env.REACT_APP_AZURE_SPEECH_SERVICE_LANGUAGE_KEY));
            const response = await client.analyze("LanguageDetection", text);
            return response.map((doc) => {
                if (!doc.error) {
                    return doc.primaryLanguage.iso6391Name;
                } else {
                    throw new Error(doc.error.message);
                }
            });
        } catch (error) {
            console.log('Error detecting language: ', error);
            return null;
        }
    }

    useEffect(() => {
        const fetchDetectedLanguage = async () => {
            if (generatedScript?.Script) { 
                const detectedLanguages = await detectLanguage([generatedScript.Script]);
                if (detectedLanguages && detectedLanguages.length > 0) {
                    setLanguageCode(detectedLanguages[0]); // Update the language code
                    console.log('Detected language: ', detectedLanguages[0]);
                }
            }
        };
    
        if (generatedScript?.Script) { 
            fetchDetectedLanguage();
        } else {
            console.log('Waiting for generatedScript to load...');
        }
    }, [generatedScript.Script]);     

    useEffect(() => {
        const id = params.videoID;
        fetchScript(id);
        if (windowMode == "form") {
            fetchAudios();
        }
        window.scrollTo(0, 0);
    }, [windowMode])

    const makeChangesForScript = async () => {
        const data = { result: generatedScript, changes: scriptChangePrompt };

        // const response=await axiosInstance.post('/modifyscript/',JSON.stringify(data),{headers:{"Content-Type":"application/json"}});
        const response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/modifyscript/', JSON.stringify(data), { headers: { "Content-Type": "application/json" } });
        console.log(response);

        setgeneratedScript(response.data);

        setScriptChangePrompt("");
    }

    useEffect(() => {
        const isTourCompleted = localStorage.getItem('tourCompleted');
        if (!isTourCompleted) {
        if (windowMode === "form" && document.getElementById("audiochoice")) {
            const tour = driver({
                showProgress: true,
                steps: [
                    {
                        element: '#audiochoice',
                        popover: {
                            title: 'Select Audio',
                            description: 'Select the desired audio from the given options.',
                            side: 'right'
                        }
                    },
                    ...(videoType === 'multilayer' ? [{
                        element: '#selectbackground',
                        popover: {
                            title: 'Select Background',
                            description: 'Select the background you want for your video.',
                            side: 'left'
                        }
                    }] : []),
                    {
                        element: '#singleword',
                        popover: {
                            title: 'Single Word Subtitles',
                            description: 'In single word subtitles, subtitles appear word by word on the screen.',
                            side: 'left'
                        }
                    },
                    {
                        element: '#singleline',
                        popover: {
                            title: 'Single Line Subtitles',
                            description: 'In single line subtitles, subtitles appear as a single line on the screen.',
                            side: 'right'
                        }
                    },
                    {
                        element: '#subtitles',
                        popover: {
                            title: 'Subtitle Style',
                            description: 'Select the best subtitle style for your video.',
                            side: 'top'
                        }
                    },
                    {
                        element: '#getvideo',
                        popover: {
                            title: 'Submit',
                            description: 'Submit and sit back to see the final video.',
                            side: 'top'
                        }
                    }
                ]
            });

            tour.drive();
            localStorage.setItem('tourCompleted', 'true');
        }

        }
    }, [windowMode]);

    return (
        <div>
            <Header2 />
            <div className={loadingState ? styles.animation : styles.hidden}>
                <Player className={styles.loadingAnimation}
                    src={videoGenerationLoadingLottie}
                    loop
                    autoplay
                />
                <h1 className={styles.LoadingText}> Hold tight! EditAI is creating your video. You'll get an email with the link soon!</h1>
            </div>
            {windowMode === "view" ? <div className={loadingState ? styles.hidden : styles.mainContainer}>

                <>
                    <div className={styles.para}>
                        <h1 className='text-xl font-medium py-3'>Generated Script</h1>
                        <div className=''>{generatedScript.Script}</div>
                    </div>
                    <div className={styles.buttonsDiv}>
                        <button className='inline-block rounded border border-indigo-600 px-12 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500 ml-4' onClick={() => setWindowMode("edit")}>Edit</button>
                        <button className='inline-block rounded border border-indigo-600 px-12 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500 ml-4' onClick={() => setWindowMode("form")}>Next <MdNavigateNext className='inline-block'/></button>
                    </div>
                </>

            </div> : null}
            <div className={loadingState ? styles.hidden : styles.editContainer}>
                {windowMode == "edit" && (

                    <>
                        {generatedScript && (
                            <div>
                                <h5 style={{ margin: 'auto' }} className="text-xl font-medium text-gray-700 mb-4 text-center" id="generatedscript">Script</h5>
                                <textarea
                                    id="OrderNotes"
                                    className="w-[90vw] md:w-[40vw] min-h-80 snap-center p-4 rounded-lg border-gray-200 shadow-sm text-sm text-gray-700"
                                    placeholder="Enter any additional order notes..."
                                    value={generatedScript.Script}
                                    onChange={(e) => {
                                        setgeneratedScript((prevScript) => ({
                                            ...prevScript,
                                            Script: e.target.value,
                                        }));
                                    }}
                                ></textarea>
                            </div>
                        )}


                        <div className={styles.promptDiv}>
                            <h5 id="enterprompt" className="text-xl font-medium text-gray-700 mb-4 text-center">Enter Prompt</h5>
                            <textarea
                                style={{ width: 350, margin: 'auto' }}
                                className="w-full h-30 p-4 rounded-lg border-gray-200 shadow-sm text-sm"
                                placeholder="Enter prompt an then let AI edit your script"
                                value={scriptChangePrompt}
                                onChange={(e) => setScriptChangePrompt(e.target.value)}
                            ></textarea>

                            <br /><br />
                            <div className="flex justify-center gap-1">

                            <button
                                    id="submit"
                                    className="inline-block rounded border border-indigo-600 px-7 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                                    onClick={() => saveScript()}
                                >
                                    Save
                                </button>

                                <button
                                    id="nextstep"
                                    className="inline-block rounded border border-indigo-600 px-7 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                                    onClick={() => { setWindowMode("view") }}
                                >
                                    Reset
                                </button>


                                <button
                                    id="nextstep"
                                    className="inline-block rounded border border-indigo-600 px-7 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                                    onClick={() => { makeChangesForScript() }}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>

                    </>
                )
                }

                {windowMode == "form" &&
                    <div className={style.finalContainer}>
                        <div className="text pt-4" id="selectaudio">
                            <p>Select Audio</p>
                        </div>
                        <div id="audiochoice" className={style.audiosContainer}>
                            {audios.map((audio) => (
                                <div
                                    key={audio.microsoft_id}
                                    className={`w-64 p-4 flex flex-row items-center justify-center gap-3 rounded-lg transition-all duration-300 shadow-md 
                                        ${languageCode !== 'en' && !audio.microsoft_id.toLowerCase().includes('multilingual') 
                                          ? 'bg-slate-100 cursor-not-allowed' 
                                          : `${audio.microsoft_id === selectedAudio ? 'bg-blue-300 shadow-xl' : 'bg-white hover:bg-slate-300 cursor-pointer'}`}`}                                      
                                    // onClick={() => setSelectedAudio(audio.microsoft_id)}
                                    onClick={(e) => {
                                        // Only allow multilingual audios for non-english languages
                                        if (languageCode !== 'en' && !audio.microsoft_id.toLowerCase().includes('multilingual')) return;
                                        e.stopPropagation();
                                        setSelectedAudio(audio.microsoft_id)
                                        const audioElement = document.getElementById(audio.microsoft_id);
                                        if (audioElement.paused) {
                                            audioElement.play();
                                            handleAudioPlay(audio.microsoft_id);
                                        } else {
                                            audioElement.pause();
                                            handleAudioPause();
                                        }
                                    }}
                                >
                                    {playingAudio === audio.microsoft_id ? (
                                        <div className='flex flex-row items-center justify-center gap-3'>
                                            {/* <div className="w-14 h-14 flex items-center justify-center rounded-full bg-red-300">
                                                <BsSoundwave />
                                            </div> */}
                                            <img src={audioAnimationGif} className="w-12 h-12 flex items-center justify-center rounded-full" />
                                            <div className="space-y-1 font-medium">
                                                <div>{audio.name}</div>
                                                <div className="text-sm text-gray-500">{audio.language}</div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='flex flex-row items-center justify-center gap-3'>
                                            <img src={audio.avatar != '' ? audio.avatar : 'https://flowbite-react.com/images/people/profile-picture-5.jpg'} className="w-14 h-14 flex items-center justify-center rounded-full" />
                                            <div className="space-y-1 font-medium">
                                                <div>{audio.name}</div>
                                                <div className="text-sm text-gray-500">{audio.language}</div>
                                            </div>
                                        </div>
                                    )}
                                    {/* <button className="mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300">
                                        {playingAudio === audio.microsoft_id ? 'Pause' : 'Play'}
                                    </button> */}
                                    <audio
                                        id={audio.microsoft_id}
                                        src={audio.audio_sample}
                                        onEnded={handleAudioPause}
                                    />
                                    {/* {playingAudio === audio.microsoft_id ? '' : ''} */}
                                </div>
                            ))}
                        </div>

                        {videoType == "multilayer" ? <div className='p-3 backgrounds' id="selectbackground">
                            <p className='text-center px-2'> Select background </p>
                            <div id="backgroundchoice" className={style.videoDiv}>
                                {backs.map((back, index) => (
                                    <div key={index} className={style.background}>
                                        <video
                                            onClick={() => setBackGroundType(back)}
                                            src={cloudinaryLinks[index]}
                                            className={backgroundType === back ? style.selected : 'rounded-lg'}
                                            loop muted autoPlay
                                        ></video>
                                    </div>
                                ))}
                            </div>


                        </div> : null}
                        <div className="buttons">

                            <p className='text-center pb-4'>Subtitle type</p>

                            <button
                                id="singleword"
                                className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitleType === 'word'
                                    ? 'text-white bg-indigo-600'
                                    : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                                    }`}
                                onClick={() => {
                                    setSubtitleType('word')
                                }}
                            >
                                Single word
                            </button>

                            <button
                                id="singleline"
                                className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitleType === 'line'
                                    ? 'text-white bg-indigo-600'
                                    : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                                    }`}
                                onClick={() => {
                                    setSubtitleType('line')

                                }}
                            >
                                Single line
                            </button>

                        </div>

                        <div className='flex-col items-center justify-center pt-4'>
                            <p className='text-center'>Subtitles</p>
                            <div className={style.subtitles} id="subtitles">
                                {subtitleList.map((sub) => (
                                    <video
                                        onClick={() => setSubtitle(sub)}
                                        src={subtitleType === 'word' ? `https://assets-editai.azureedge.net/videos-subtitles/${sub}_an_portim.mp4` : `/assets/demovideos/subtitles/${sub}.mp4`}
                                        className={subtitle === sub ? style.selected : 'rounded-lg'}
                                        loop muted autoPlay
                                    ></video>))}
                            </div>
                        </div>
                        <div className="button-container" id="getvideo">
                            <button className="inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500 ml-4" onClick={() => getVideo()} disabled={buttonDisabled}>Get Video</button>
                        </div>

                    </div>
                }

            </div>
            <Footer2 />
        </div>
    )
}
