import Layout from "components/layout/Layout";

const Texts = ({ heading, texts }) => {
    return (
        <div className="flex flex-col w-2/3">
            <h5 className="text-neutral-600 font-semibold text-justify mt-2 mb-2 wow fadeup" data-wow-delay=".2s">
                {heading}
            </h5>
            <ol className="list-decimal text-neutral-500 text-justify pl-5 wow fadeInUp mb-3" data-wow-delay=".2s">
                {texts.map((text, index) => (
                    <li key={index} className="mb-2">
                        {text}
                    </li>
                ))}
            </ol>
        </div>
    );
};

const Heads = () => {
    return (
        <section className="banner-area-three p-0">
            <div className="banner-shape bg-white opacity-100" data-background="assets/img/banner/h3_banner_shape.png" />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-9 col-lg-10">
                        <div className="banner-content-three text-center flex flex-col align-middle items-center">
                            <h2 className="text-slate-700 text-6xl my-4 font-extrabold wow fadeInUp" data-wow-delay=".2s">
                                Return and Refund Policy
                            </h2>
                            <b className="font-mono text-slate-400">Last updated: 31st August 2024</b>

                            {/* Subscription Refund Policy */}
                            <h3 className="text-neutral-400 text-4xl text-justify my-4 font-bold wow fadeInUp" data-wow-delay=".2s">
                                Subscription Refund Policy
                            </h3>

                            <Texts
                                heading="No Refund or Return Policy:"
                                texts={[
                                    "Edict AI does not provide refunds or returns for subscription purchases. Once a subscription is bought, it is considered final and non-refundable. Please ensure that you carefully review our product description and features before making a purchase."
                                ]}
                            />

                            {/* Cancellation of Subscription */}
                            <h3 className="text-neutral-400 text-4xl text-justify my-4 font-bold wow fadeInUp" data-wow-delay=".2s">
                                Cancellation of Subscription
                            </h3>

                            <Texts
                                heading="Automatic Renewals:"
                                texts={[
                                    "Edict AI subscriptions may be set to automatically renew at the end of the subscription period. You can manage your subscription and turn off auto-renewal by going to your account settings before the renewal date."
                                ]}
                            />

                            <Texts
                                heading="Effect of Cancellation:"
                                texts={[
                                    "If you choose to cancel your subscription before the end of the current subscription period, your subscription will remain active until the end of that period. However, no refunds will be provided for the remaining unused portion of the subscription."
                                ]}
                            />

                            {/* Changes to this Refund Policy */}
                            <h3 className="text-neutral-400 text-4xl text-justify my-4 font-bold wow fadeInUp" data-wow-delay=".2s">
                                Changes to this Refund Policy
                            </h3>

                            <Texts
                                heading=""
                                texts={[
                                    "We reserve the right to update or modify this Refund Policy at any time. Any changes will be effective immediately upon posting the updated policy on the Edict AI website."
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

// Main Refund Policy Component
const RefundPolicy = () => {
    return (
        <Layout mainCls="main-content-two fix" headerStyle={2} footerStyle={2}>
            <Heads />
        </Layout>
    );
};

export default RefundPolicy;
