import Layout from "components/layout/Layout";

const Texts = ({ heading, texts }) => {
    return (
        <div className="flex flex-col w-2/3">
            <h5 className="text-neutral-600 font-semibold text-justify mt-2 mb-2 wow fadeup" data-wow-delay=".2s">
                {heading}
            </h5>
            <ol className="list-decimal text-neutral-500 text-justify pl-5 wow fadeInUp mb-3" data-wow-delay=".2s">
                {texts.map((text, index) => (
                    <li key={index} className="mb-2">
                        {text}
                    </li>
                ))}
            </ol>
        </div>
    );
};

const Heads = () => {
    return (
        <section className="banner-area-three p-0">
            <div className="banner-shape bg-white opacity-100" data-background="assets/img/banner/h3_banner_shape.png" />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-9 col-lg-10">
                        <div className="banner-content-three text-center flex flex-col align-middle items-center">
                            <h2 className="text-slate-700 text-6xl my-4 font-extrabold wow fadeInUp" data-wow-delay=".2s">
                                Terms and Conditions
                            </h2>
                            <b className="font-mono text-slate-400">Last updated: 31st August 2024</b>
                            <p className="text-black text-center wow fadeInUp mt-4" data-wow-delay=".4s">
                                Thank you for choosing Edit AI, an AI-powered multi-layered video editor. <br />
                                These Terms and Conditions govern your use of Edit AI's website and services. <br />
                                By using Edit AI, you agree to abide by these terms.
                            </p>

                            <h3 className="text-neutral-400 text-5xl my-4 font-bold wow fadeInUp" data-wow-delay=".2s">
                                <b>Details</b>
                            </h3>

                            {/* Acceptance of Terms Section */}
                            <Texts
                                heading="Acceptance of Terms"
                                texts={[
                                    "By accessing or using Edit AI, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use Edit AI.",
                                ]}
                            />

                            {/* Use of Edit AI Section */}
                            <Texts
                                heading="Use of Edit AI"
                                texts={[
                                    "Eligibility: You must be at least 8 years old to use Edit AI. By using Edit AI, you represent and warrant that you meet this eligibility requirement.",
                                    "User Account: To access certain features of Edit AI, you may need to create an account. You are responsible for maintaining the confidentiality of your account information.",
                                ]}
                            />

                            {/* Video Editing Services Section */}
                            <Texts
                                heading="Video Editing Services"
                                texts={[
                                    "Content Ownership: You retain ownership of the content you provide for video editing. Edit AI does not claim any ownership rights over the edited videos.",
                                    "Acceptable Use: You agree not to use Edit AI for any unlawful, abusive, or fraudulent purposes. Edit AI reserves the right to suspend or terminate your account for violation of these terms.",
                                ]}
                            />

                            {/* User Content Section */}
                            <Texts
                                heading="User Content"
                                texts={[
                                    "Responsibility: You are solely responsible for the content you provide for video editing. Edit AI is not responsible for the accuracy, legality, or appropriateness of user-provided content.",
                                    "Permissions: By providing content to Edit AI, you grant Edit AI the right to use, modify, reproduce, and distribute the edited videos for the purpose of providing the video editing services.",
                                ]}
                            />

                            {/* Privacy Section */}
                            <Texts
                                heading="Privacy"
                                texts={[
                                    <span key="privacy">
                                        Your use of Edict AI is also governed by our Privacy Policy, which can be found {' '}
                                        <a href="https://websites.godaddy.com/en-IN/editor/f3dd86d3-63b9-4ba8-8cdc-1363dbd4c8b8/287f429b-ed6e-416e-aa2a-319409973b79" className="text-blue-600 underline">
                                            here
                                        </a>.
                                        By using Edict AI, you consent to the practices outlined in the Privacy Policy.
                                    </span>
                                ]}
                            />


                            {/* Intellectual Property Section */}
                            <Texts
                                heading="Intellectual Property"
                                texts={[
                                    "Edit AI Property: Edit AI and its associated logos, trademarks, and content are the property of Closed AI Chain. You may not use these without prior written permission.",
                                    "User Feedback: Any feedback or suggestions provided by users may be used by Edit AI without any obligation or compensation.",
                                ]}
                            />

                            {/* Limitation of Liability Section */}
                            <Texts
                                heading="Limitation of Liability"
                                texts={[
                                    'Edit AI is provided "as is" without any warranty. In no event shall Edit AI be liable for any direct, indirect, incidental, special, or consequential damages.',
                                ]}
                            />

                            {/* Changes to Terms Section */}
                            <Texts
                                heading="Changes to Terms and Conditions"
                                texts={[
                                    "Edit AI may update these Terms and Conditions to reflect changes in services or applicable laws. It is your responsibility to review these terms periodically. Your continued use of Edit AI after any changes indicates your acceptance of the updated terms.",
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

// Main TermsAndConditions Component
const TermsAndConditions = () => {
    return (
        <Layout mainCls="main-content-two fix" headerStyle={2} footerStyle={2}>
            <Heads />
        </Layout>
    );
};

export default TermsAndConditions;
