import Layout from "components/layout/Layout";
import Header2 from 'components/header/Header2';
import Footer2 from 'components/footer/footer2';
import { Link } from "react-router-dom";
import React, { useState, useRef } from 'react';
// import './Gallery/style.css';

const styles2 = `
@import url("https://fonts.googleapis.com/css2?family=Figtree&display=swap");

.containerGallery {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0.5em;
  width: 800px;
  height: 450px;
  transition: all 400ms;
}

.containerGallery:hover .boxGallery {
  filter: grayscale(100%) opacity(24%);
}

.boxGallery {
  position: relative;
  background: var(--img) center center;
  background-size: cover;
  transition: all 400ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerGallery .boxGallery:hover {
  filter: grayscale(0%) opacity(100%);
}

.containerGallery:has(.boxGallery-1:hover) {
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
}

.containerGallery:has(.boxGallery-2:hover) {
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
}

.containerGallery:has(.boxGallery-3:hover) {
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr;
}

.containerGallery:has(.boxGallery-4:hover) {
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr;
}

.containerGallery:has(.boxGallery-5:hover) {
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;
}
.containerGallery:has(.boxGallery-6:hover) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
  }

.boxGallery:nth-child(odd) {
  transform: translateY(-12px);
  // filter: grayscale(80%) opacity(24%);
  }
  
  .boxGallery:nth-child(even) {
    transform: translateY(24px);
    // filter: grayscale(80%) opacity(48%);
  }

.boxGallery::after {
  content: attr(data-text);
  position: absolute;
  bottom: 15px;
  background: #ffcacc;
  color: #000;
  padding: 10px 10px 10px 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  transform: translateY(60px);
  opacity: 0;
  transition: all 400ms;
  font-size: 2vh;
  font-weight: 400;
  border-radius: 1em;
}

.boxGallery:hover::after {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 400ms;
}

.containerGalleryBox{
  padding-bottom: 2vh;
}

@media only screen and (max-width: 540px){
  .containerGallery {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    width: 96vw;
    transition: all 400ms;
  }
  
  .containerGallery:hover .boxGallery {
    filter: grayscale(100%) opacity(24%);
  }
  
  .boxGallery {
    position: relative;
    background: var(--img) center center;
    background-size: cover;
    transition: all 400ms;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .containerGallery .boxGallery:hover {
    filter: grayscale(0%) opacity(100%);
  }
  
  .containerGallery:has(.boxGallery-1:hover) {
    grid-template-columns: 2fr 1fr 1fr;
  }
  
  .containerGallery:has(.boxGallery-2:hover) {
    grid-template-columns: 1fr 2fr 1fr;
  }
  
  .containerGallery:has(.boxGallery-3:hover) {
    grid-template-columns: 1fr 1fr 2fr;
  }

  .boxGallery-4 {
    display: none;
  }

  .boxGallery-5 {
    display: none;
  }

  .boxGallery-6 {
    display: none;
  }

  .boxGallery:nth-child(odd) {
    transform: translateY(-16px);
  }
  
  .boxGallery:nth-child(even) {
    transform: translateY(16px);
  }
  
  .boxGallery::after {
    content: attr(data-text);
    position: absolute;
    bottom: 20px;
    background: #ffcacc;
    color: #000;
    padding: 10px 10px 10px 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    transform: translateY(60px);
    opacity: 0;
    transition: all 400ms;
    font-size: 2vh;
    font-weight: 400;
    border-radius: 1em;
  }
  
  .boxGallery:hover::after {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 400ms;
  }
  .containerGalleryBox{
    padding-bottom: 2vh;
  }
}

`;

const Gallery2 = ({src}) => {
  
  const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  const [isPlaying, setIsPlaying] = useState(false);

  const handleMouseOver = (videoIndex) => {
    if (!isPlaying) {
      videoRefs[videoIndex].current.play();
      setIsPlaying(true);
    }
  };
  
  const handleMouseOut = (videoIndex) => {
    if (isPlaying) {
      videoRefs[videoIndex].current.pause();
      setIsPlaying(false);
    }
  };

  const videos = [
    {src: "https://assets-editai.azureedge.net/videos/1721037227_user_2_1178.mp4", text: "Ritesh Agarwal"},
    {src: "https://assets-editai.azureedge.net/videos-webm/1720123632_user_17_916.mp4", text: "Mr. Beast"},
    {src: "https://assets-editai.azureedge.net/videos-webm/1720277413_user_2_962.mp4", text: "Delhi Liqour Scam"}, 
    {src: "https://assets-editai.azureedge.net/videos-webm/1720267053_user_2_952.mp4", text: "Kim Kardashian"}, 
    {src: "https://assets-editai.azureedge.net/videos-webm/1720619886_user_2_1052.mp4", text: "Mustansir Bohari"},
    {src: "https://assets-editai.azureedge.net/videos/1720805147_user_2_1081.mp4", text: "Just Ojazz"}
    // {src: "https://assets-editai.azureedge.net/videos-webm/1720037862_user_17_875.mp4", text: "Beast Mansion"},
    // {src: "https://assets-editai.azureedge.net/videos-webm/1713603486_user_6_102.mp4", text: "Rashmika Mandana"},
    // {src: "https://res.cloudinary.com/dkisppdc7/video/upload/v1713612620/user_1_108.webm", text: "David Goggins"},
    // {src: "https://assets-editai.azureedge.net/videos-webm/1713602535_user_3_101.mp4", text: "Danish"},
  ]

  return (
    <>
      <div className="containerGalleryBox">
        <style>{styles2}</style>
        <div className="containerGallery">
          {videos.map((video, index) => (
            <div className={"boxGallery boxGallery-"+(index+1)} data-text={video.text} key={index}>
              <video
                style={{ objectFit: 'cover', zIndex: -1, position: 'absolute', borderRadius: '1em'}}
                ref={videoRefs[index]}
                onMouseOver={() => handleMouseOver(index)}
                onMouseOut={() => handleMouseOut(index)}
                muted
                loop
                src={video.src}
              />
            </div>
          ))}          
        </div>
        <Link to="/ai-footage-editing" className="btn">
          Footage editing
        </Link>
      </div>
    </>
  );
}
export default Gallery2