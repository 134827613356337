import React from 'react';
import styles from './style.module.css';
import { Link } from "react-router-dom";

export default function Features() {
  return (
    <div className={styles.mainContainer}>
      <section className="work-area" style={{ backgroundColor: "white", color: "black" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Link style={{ textDecoration: "none", color: 'inherit' }} to="/ai-footage-editing"
                className="work-item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="work-thumb">
                  <video src="https://assets-editai.azureedge.net/videos/1716219192_Fe_Wqruc6_bqs88h.mp4" alt="footage editing" loop autoPlay muted />
                </div>

                {/* 1 */}
                <div className="flex-col work-content">
                  <span>Footage Editing</span>
                  <h2 className="text-3xl text-justify">Transform raw footage into Fully Edited video</h2>
                  <div className='flex-col text-justify'>
                    <div className='flex'>
                      <div className='text-base justify-between pt-2'>
                        Automatically enhance your raw footage with <b>eye-catching images, graphics, and b-rolls.</b> Effortlessly integrate <b>trendy transitions and zoom effects,</b> and immersive <b>sound effects (SFX)</b>
                        <div className='pt-1'>Generate <b>automatic subtitles</b> to enhance accessibility and engagement
                        {/* <Link to="/feform" > ↗️ </Link> */}
                        </div>
                      </div>
                    </div>

                    <h3 className='py-2'>
                      <b>Use Cases</b>
                    </h3>
                    <div className='flex flex-wrap items-center justify-start gap-2'>
                      <div className='border-s-2 border-t-2 border-purple-500 rounded px-3 py-1.5 text-sm'>Marketing Videos</div>
                      <div className='border-s-2 border-t-2 border-purple-500 rounded px-3 py-1.5 text-sm'>Startups</div>
                      <div className='border-s-2 border-t-2 border-purple-500 rounded px-3 py-1.5 text-sm'>Advertisement</div>
                    </div>
                  </div>

                </div>

              </Link>

              <Link style={{ textDecoration: "none", color: 'inherit' }} to="/text-to-video"
                className="work-item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="work-thumb">
                  <video src="https://assets-editai.azureedge.net/videos/1716219598_T2v_Multi_Dgkeuz_lufbj0.mp4" alt="text to video multilayer" loop autoPlay muted />
                </div>

                {/* 2 */}
                <div className="work-content">
                  <span>Text to Video</span>
                  <h2 className="text-3xl text-justify">Get videos with a prompt or some persoanlized content</h2>
                  <div className='flex-col text-justify'>
                    <div className='flex'>
                      <div className='text-base justify-between pt-2'>
                      Automatically enhance your prompt or personalized content with <b>eye-catching images, graphics, and b-rolls.</b> Effortlessly integrate <b>trendy transitions and zoom effects,</b> and immersive <b>sound effects (SFX)</b>
                        <div className='pt-1'>Generate <b>automatic subtitles</b> to enhance accessibility and engagement
                        {/* <Link to="/t2vform" > ↗️ </Link> */}
                        </div>
                      </div>
                    </div>

                    <h3 className='py-2'>
                      <b>Use Cases</b>
                    </h3>
                    <div className='flex flex-wrap items-center justify-start gap-2'>
                      <div className='border-e-2 border-b-2 border-purple-500 rounded px-3 py-1.5 text-sm'>Explainer</div>
                      <div className='border-e-2 border-b-2 border-purple-500 rounded px-3 py-1.5 text-sm'>Invitation</div>
                      <div className='border-e-2 border-b-2 border-purple-500 rounded px-3 py-1.5 text-sm'>Case Study</div>
                    </div>
                  </div>

                </div>

              </Link>

              <Link style={{ textDecoration: "none", color: 'inherit' }} to="/microservices/autosubtitles"
                className="work-item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="work-thumb">
                  <video src="https://assets-editai.azureedge.net/videos/1716218951_Subtitle_Micro_Grgo2j_nitabg.mp4" alt="subtitles" loop autoPlay muted />
                </div>
                <div className="work-content">
                  <span>Auto Accurate Subtitle/Captions</span>
                  <h2 className="text-3xl pb-3 text-justify">
                    Trendy animated subtitles in a single click!
                  </h2>
                  <div className='flex flex-row flex-wrap gap-1.5 pb-2'>
                    <div className='border-s-2 border-t-2 border-purple-500 rounded min-w-fit px-3 py-1.5 text-sm'><b>Trendy</b> Subtitle Templates</div>
                    <div className='border-s-2 border-t-2 border-purple-500 rounded min-w-fit px-3 py-1.5 text-sm'>Engaging <b>Animations</b></div>
                    <div className='border-s-2 border-t-2 border-purple-500 rounded min-w-fit px-3 py-1.5 text-sm'>Works on Mobile</div>
                    <div className='border-s-2 border-t-2 border-purple-500 rounded min-w-fit px-3 py-1.5 text-sm'>Simple To use</div>
                    <div className='border-s-2 border-t-2 border-purple-500 rounded min-w-fit px-3 py-1.5 text-sm'>Multiple Languages </div>
                  </div>
                  <Link style={{ fontSize: "0.8rem", fontStyle: "italic"}} to="/microservices/autosubtitles" >
                    Learn More↗️
                  </Link>
                </div>
              </Link>

              <Link style={{ textDecoration: "none", color: 'inherit' }} to="/microservices/backgroundremover"
                className="work-item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <div className="work-thumb">
                  <video src="https://assets-editai.azureedge.net/videos/editai_microservice_video_background_removal_demo.mp4" alt="" loop autoPlay muted />
                </div>
                <div className="work-content">
                  <span>Video Background Removal</span>
                  <h2 className="text-3xl pb-3 text-justify">FREE &nbsp;Video Background Remover</h2>
                  <div className='flex flex-row flex-wrap gap-1.5 pb-2'>
                    <div className='border-e-2 border-b-2 border-purple-500 rounded min-w-fit px-3 py-1.5 text-sm'><b>Automatic Background removal</b> in a single click!</div>
                    <div className='border-e-2 border-b-2 border-purple-500 rounded min-w-fit px-3 py-1.5 text-sm'>No need of green screen!</div>
                    <div className='border-e-2 border-b-2 border-purple-500 rounded min-w-fit px-3 py-1.5 text-sm'> No login or sign-up required!</div>
                  </div>
                  <Link style={{ fontSize: "0.8rem", fontStyle: "italic" }} to="/microservices/backgroundremoval" className="card_btn">
                    Learn More↗️
                  </Link>
                </div>
              </Link>

            </div>
          </div>
        </div>
        <div style={{ marginBottom: "4rem" }} className="banner-btn-two">
          <Link to="/tools" className="gradient-btn gradient-btn-three wow fadeInLeft">
            Explore Edit AI tools
          </Link>
        </div>
      </section>
    </div>
  )
}
