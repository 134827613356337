
import Header2 from "components/header/Header2"
import DataBg from "components/layout/DataBg"
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export default function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const navigate=useNavigate();
    const location = useLocation();
    const handlesubmit = async () => {
        try {
            const from = location.state?.from?.pathname || '/';
            
            if (!email || !password) {
                return alert("fill all the fields")
            }
            const data = { email, password }
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL+'/auth/jwt/create/', JSON.stringify(data), { headers: { "content-type": "application/json" } });
            console.log(response);
            try {
                // Decode the JWT token
                const decodedToken = jwtDecode(response.data.refresh);
                const decodedAccessToken = jwtDecode(response.data.access);

                
                // Get the expiry date from the token
                const expiryDate = new Date(decodedToken.exp * 1000);
                const accessExpiry=new Date(decodedAccessToken.exp *1000);
                // Check if the token is already expired
                if (expiryDate < new Date()) {
                    console.error("The token has already expired.");
                    return;
                }
                
                // Set a cookie that expires on the token's expiry date
                Cookies.set('refreshToken', response.data.refresh, { expires: expiryDate });
                Cookies.set('accessToken', response.data.access, { expires: accessExpiry });
                
                console.log(`Cookie set to expire on ${expiryDate}`);
            } catch (error) {
                console.error("Error decoding token or setting cookie:", error);
            }
            // Cookies.set('refreshToken', response.data.refresh, { expires: 7 });
            navigate(from, { replace: true });
        }
        catch (err) {
            console.log(err);
            if (err.response.status == 401) {
                alert(err.response.data.detail);
            }
        }
    }

    const googleLogin = async () => {
        console.log("google")
        try {
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"

                }
            }
            axios.defaults.withCredentials = true;
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL+"/auth/o/google-oauth2/?redirect_uri=https://editai.in/google_auth/", config);
            console.log(response)
            window.location = response.data.authorization_url;
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <DataBg />
            <Header2 />
            <main className="main-content">
                <div className="noise-bg" data-background="/assets/img/bg/noise_bg.png" />
                <div className="main-shape" data-background="/assets/img/images/main_shape.png" />
                <section className="login-area" style={{padding:'7vw'}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-10 pb-12">
                                <div className="login-content ms-6">
                                    <h3 className="title">Create your account</h3>
                                    <span>👋 Welcome back! Please enter your details.</span>
                                    <form onSubmit={(e) => { e.preventDefault(); handlesubmit() }}>
                                        <div className="form-grp">
                                            <label htmlFor="email">Your Email</label>
                                            <input type="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                        </div>
                                        <div className="form-grp">
                                            <label htmlFor="word">Password</label>
                                            <input type="password" id="word" value={password} onChange={(e)=>setPassword(e.target.value)}  />
                                        </div>
                                        <div className="password-wrap">
                                            
                                            <button><Link to="/forgotpassword">Forgot Password</Link></button>
                                        </div>
                                        <button type="submit" className="sine-btn">sign in</button>
                                        <span>Don’t have an account? <Link to="/signup">Sign up for free</Link></span>
                                        

                                    </form>
                                    <button className="google-btn" onClick={()=>googleLogin()}><img src="assets/img/images/google.gif" alt="" height={50} width={50}/> sign in with google</button>
                                    
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="login-right-content-wrap">
                                    <div className="login-right-bg" data-background="/assets/img/bg/error_bg.jpg" />
                                    <div className="login-right-content-inner">
                                        <img src="/assets/img/images/loginpageImg.png" alt="" />
                                        <h4 className="title">Revolutionize Your Content: Try <span>Edit AI Today</span> and watch your content soar</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    )
}