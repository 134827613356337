import axios from "axios";
import Layout from "components/layout/Layout";
import { useState } from "react";

const Contact = () => {
  const [formData,setFormData]=useState({
    name:"",
    email:"",
    phone:"",
    message:""
  })

  const handleChange=(e)=>{
    setFormData({
      ...formData,
      [e.target.name]:e.target.value
    })
  }

  const handleSubmit=async(e)=>{
    e.preventDefault();
    console.log(formData);
    const config={
      headers:{
        "Content-Type":"application/json"
      }
    }
    if(formData.name && formData.email && formData.message){
      try{
        const response= await axios.post(process.env.REACT_APP_BACKEND_URL+"/contactus/",formData,config);
        console.log(response);
        if(response.status===201){
          alert("Your response has been recorded");
          setFormData({
            name:"",
            email:"",
            phone:"",
            message:""
          })
        }
      }
      catch(error){
        console.log(error);
        alert("Something went wrong")
      }
    }
  }
  return (
    <Layout
      headerStyle={2}
      footerStyle={2}>
      <div>

        <section className="breadcrumb-area pt-16 pb-3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-content">
                  <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                    Get in <span>Touch</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-area pb-140">
          <div className="container">
            <div className="contact-info-wrap">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="content">
                      <h2 className="title">Visit Us</h2>
                      <p>
                        Vishwakarma Institute of Technology <br /> 411037, Pune, India
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-phone-volume" />
                    </div>
                    <div className="content">
                      <h2 className="title">Contact Us</h2>
                      <span>+91 988 121 0225</span>
                      <span>+91 878 804 8237</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-envelope" />
                    </div>
                    <div className="content">
                      <h2 className="title">Email Us</h2>
                      <span>team@editai.in</span>
                      <span>mustansir@editai.in</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-form-wrap">
                  <h2 className="title">
                    Do you have <span>question contact us</span>
                  </h2>
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="responds-wrap">
                        <ul className="list-wrap">
                          <li>
                            <img
                              src="assets/img/services/support-pink-panther.jpg"
                              alt="Mohit Chawla"
                              className="h-14 w-14 object-cover"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/img/services/support-kung-fu-panda.jpg"
                              alt="Mustansir Bohari"
                              className="h-14 w-14 object-scale-down"
                            />
                          </li>
                          <li>
                            <img
                              src="assets/img/services/support-spider-man.jpg"
                              alt="Asif Mursal"
                              className="h-14 w-14 object-cover"
                            />
                          </li>
                        </ul>
                        <p>We’ll get back to you!</p>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="contact-form">

                        <form onSubmit={handleSubmit}>
                          <div className="form-grp border-2 border-x-blue-300 rounded-lg">

                            <input
                              type="text"
                              id="name"
                              placeholder="Your name"
                              name="name"
                              onChange={handleChange}
                              value={formData.name}
                              required
                              className="!text-gray-600 !placeholder-slate-500"
                            />
                          </div>
                          <div className="form-grp border-2 border-x-blue-300 rounded-lg">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Your email *"
                              required
                              onChange={handleChange}
                              value={formData.email}
                              className="!text-gray-600 !placeholder-slate-500"
                            />
                          </div>
                          <div className="form-grp border-2 border-x-blue-300 rounded-lg">
                            <input
                              type="text"
                              id="phone"
                              name="phone"
                              placeholder="Your phone number"
                              onChange={handleChange}
                              value={formData.phone}
                              className="!text-gray-600 !placeholder-slate-500"
                            />
                          </div>
                          <div className="form-grp border-2 border-x-blue-300 rounded-lg">
                            <textarea
                              id="message"
                              name="message"
                              onChange={handleChange}
                              value={formData.message}
                              placeholder="Please describe what you need *"
                              required
                              className="!text-gray-600 !placeholder-slate-500"
                            />
                          </div>
                          <button className="btn bg-blue-300 border border-white" type="submit">
                            submit here
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="contact-shape">
                    <img src="https://themeadapt.com/tf/dexai/assets/img/images/contact_shape.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact-area-end */}
        {/* contact-map */}
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15137.745119312209!2d73.8679719!3d18.4638871!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x321bdae2cea9f064!2sVishwakarma+Institute+of+Technology!5e0!3m2!1sen!2sin!4v1462969052254"
            allowFullScreen
            loading="lazy"
          />
        </div>
        {/* contact-map-end */}
      </div>
    </Layout>
  );
};
export default Contact;
