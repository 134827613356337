import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import Header2 from 'components/header/Header2';
import Footer2 from 'components/footer/footer2';
import { useParams } from 'react-router-dom';
import axiosInstance from 'axiosInstance';
import { Clipboard } from "flowbite-react";
import { TwitterShareButton, WhatsappShareButton, LinkedinShareButton, TelegramShareButton } from 'react-share';
import { data } from 'autoprefixer';
import { useNavigate } from 'react-router-dom';

export default function MainForm(props) {

  const params = useParams();

  const [videoURL, setVideoURL] = useState("");
  const [feedback, setFeedback] = useState("");
  const [reaction, setReaction] = useState("");
  const [videoDetailsJson, setvideoDetailsJson] = useState({});
  const [buttonDisabled,setButtonDisabled] = useState(false);
  const navigate = useNavigate()

  const getVideoLink = async () => {
    try {
      let id = params.videoId
      let response;
      if (params.servicetype == "mainservices") {
        response = await axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/getvideolink/${id}`);
      }
      else {
        response = await axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/microservices/getmicroserviceslink/${id}`);
      }
      if (response.data.success == 0) {
        return alert(response.data.message)
      }
      setvideoDetailsJson(response.data);
      console.log(response.data);
      let link = response.data.link;
      setVideoURL(link);
    }
    catch (e) {
      console.log(e);
    }
  }

  const postfeedback = async () => {
    if (feedback === "" && reaction === "") {
      alert("Please fill all the fields");
      return;
    }
    try {
      setButtonDisabled(true);
      const data = {
        feedback: feedback,
        reaction: reaction,
        hash_id: params.videoId

      }
      const response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/postfeedback/', data);
      console.log(response)
      alert(response.data.message)
    }
    catch (e) {
      setButtonDisabled(false);
      console.log(e);
    }
  }

  useEffect(() => {
    getVideoLink();

  }, [])

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredwhatsapp, setIsHoveredwhatsapp] = useState(false);
  const [isHoveredlinkedin, setIsHoveredlinkedin] = useState(false);
  const [isHoveredtelegram, setIsHoveredtelegram] = useState(false);

  const cardStyle = {
    cursor: 'pointer',
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
    backgroundColor: isHovered ? '#03A9F4' : 'initial',
    transition: 'transform 0.2s, background-color 0.2s',
  };
  const cardStyleLinkedin = {
    cursor: 'pointer',
    transform: isHoveredlinkedin ? 'scale(1.1)' : 'scale(1)',
    backgroundColor: isHoveredlinkedin ? '#F6F193' : 'initial',
    transition: 'transform 0.2s, background-color 0.2s',
  };
  const cardStyleWhatsapp = {
    cursor: 'pointer',
    transform: isHoveredwhatsapp ? 'scale(1.1)' : 'scale(1)',
    backgroundColor: isHoveredwhatsapp ? '#B4E380' : 'initial',
    transition: 'transform 0.2s, background-color 0.2s',
  };
  const cardStyleTelegram = {
    cursor: 'pointer',
    transform: isHoveredtelegram ? 'scale(1.1)' : 'scale(1)',
    backgroundColor: isHoveredtelegram ? '#BEADFA' : 'initial',
    transition: 'transform 0.2s, background-color 0.2s',
  };

  return (
    <>
      <Header2 />
      <div className={styles.mainContainer}>
        <div className={styles.copyLinkButton}>
          <div className={styles.main}>
            <div className="border-2 p-3 border-slate-200  grid grid-cols-6 gap-2 rounded-xl text-sm">
              <h1 className="font-mono text-center text-stone-700 text-xl font-bold col-span-6">Title</h1>
              <div className="font-sans text-center text-md col-span-6">
                {videoDetailsJson ? videoDetailsJson.title : ""}
              </div>
            </div>
            {videoDetailsJson.script_json ?
              <div className="border-2 p-3 border-slate-200  grid grid-cols-6 gap-2 rounded-xl text-sm">
                <h1 className="font-mono text-center text-stone-700 text-xl font-bold col-span-6">Prompt</h1>
                <div className="font-sans text-md col-span-6 max-h-40 max-w-64 overflow-auto scrollbar-style-8 p-2">
                  {videoDetailsJson ? videoDetailsJson.script_json.user_prompt.content : ""}
                </div>
              </div>
              : ""}
          </div>
        </div>
        <div className='m-1'>
          <video className={styles.finalvideo} src={videoURL} controls></video>
        </div>
        <div className={styles.copyLinkButton}>
          <div className={styles.main}>
            <div className="grid grid-cols-6 gap-2 pb-1 rounded-xl text-sm">
              <h1 className="font-mono text-center text-stone-700 text-xl font-bold col-span-6">Share</h1>
            </div>
            <div className={styles.up}>
              <WhatsappShareButton className={styles.card1} url={window.location.href} title="Check out this video made with Edit AI" style={cardStyleWhatsapp} onMouseEnter={() => setIsHoveredwhatsapp(true)} onMouseLeave={() => setIsHoveredwhatsapp(false)}>
                <img src="/assets/img/socials/whatsapp.png" className='w-8 h-8 mt-3 ml-10'></img>
              </WhatsappShareButton>
              <TwitterShareButton className={styles.card2} url={window.location.href} title="Check out this video made with Edit AI" via="editai" hashtags={["coolVideo", "videoEditing"]} style={cardStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30px" height="30px" className={styles.twitter}><path d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"></path></svg>
              </TwitterShareButton>
            </div>
            <div className={styles.down}>
              <LinkedinShareButton className={styles.card3} url={window.location.href} title="Check out this video made with Edit AI" style={cardStyleLinkedin} onMouseEnter={() => setIsHoveredlinkedin(true)} onMouseLeave={() => setIsHoveredlinkedin(false)}>
                <img src="/assets/img/socials/linkedin.png" className='w-8 h-8 mb-2 ml-10'></img>
                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px" className={styles.github}>    <path d="M15,3C8.373,3,3,8.373,3,15c0,5.623,3.872,10.328,9.092,11.63C12.036,26.468,12,26.28,12,26.047v-2.051 c-0.487,0-1.303,0-1.508,0c-0.821,0-1.551-0.353-1.905-1.009c-0.393-0.729-0.461-1.844-1.435-2.526 c-0.289-0.227-0.069-0.486,0.264-0.451c0.615,0.174,1.125,0.596,1.605,1.222c0.478,0.627,0.703,0.769,1.596,0.769 c0.433,0,1.081-0.025,1.691-0.121c0.328-0.833,0.895-1.6,1.588-1.962c-3.996-0.411-5.903-2.399-5.903-5.098 c0-1.162,0.495-2.286,1.336-3.233C9.053,10.647,8.706,8.73,9.435,8c1.798,0,2.885,1.166,3.146,1.481C13.477,9.174,14.461,9,15.495,9 c1.036,0,2.024,0.174,2.922,0.483C18.675,9.17,19.763,8,21.565,8c0.732,0.731,0.381,2.656,0.102,3.594 c0.836,0.945,1.328,2.066,1.328,3.226c0,2.697-1.904,4.684-5.894,5.097C18.199,20.49,19,22.1,19,23.313v2.734 c0,0.104-0.023,0.179-0.035,0.268C23.641,24.676,27,20.236,27,15C27,8.373,21.627,3,15,3z"></path></svg> */}
              </LinkedinShareButton>
              <TelegramShareButton className={styles.card4} url={window.location.href} title="Check out this video made with Edit AI" style={cardStyleTelegram} onMouseEnter={() => setIsHoveredtelegram(true)} onMouseLeave={() => setIsHoveredtelegram(false)}>
                <img src="/assets/img/socials/telegram.png" className='w-8 h-8 mb-2 ml-5'></img>
                {/* <svg height="30px" width="30px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className={styles.discord}><path d="M40,12c0,0-4.585-3.588-10-4l-0.488,0.976C34.408,10.174,36.654,11.891,39,14c-4.045-2.065-8.039-4-15-4s-10.955,1.935-15,4c2.346-2.109,5.018-4.015,9.488-5.024L18,8c-5.681,0.537-10,4-10,4s-5.121,7.425-6,22c5.162,5.953,13,6,13,6l1.639-2.185C13.857,36.848,10.715,35.121,8,32c3.238,2.45,8.125,5,16,5s12.762-2.55,16-5c-2.715,3.121-5.857,4.848-8.639,5.815L33,40c0,0,7.838-0.047,13-6C45.121,19.425,40,12,40,12z M17.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C21,28.209,19.433,30,17.5,30z M30.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C34,28.209,32.433,30,30.5,30z"></path></svg> */}
              </TelegramShareButton>
            </div>
          </div>
          <div className={styles.feedbackLink}>
          <div className="border-b-2 pb-3 border-slate-300 grid w-full max-w-80">
              <div className="flex items-center justify-center">
                {/* <button onClick={()=>{navigate(`/editvideo/${params.videoId}`)}} className='relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800'>
                  <span className='relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0'>
                    Edit Video
                  </span>
                </button> */}
                <button onClick={()=>{navigate(`/editvideo/${params.videoId}`)}} className="relative flex items-center px-6 py-3 overflow-hidden font-medium transition-all bg-indigo-400 rounded-full group">
                  <span className="absolute top-0 right-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-indigo-500 rounded group-hover:-mr-4 group-hover:-mt-4">
                    <span class="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white"></span>
                  </span>
                  <span className="absolute bottom-0 rotate-180 left-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-indigo-500 rounded group-hover:-ml-4 group-hover:-mb-4">
                    <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white"></span>
                  </span>
                  <span className="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full bg-indigo-500 rounded-md group-hover:translate-x-0"></span>
                  <span className="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-white">
                    Edit Video 
                  </span>
                </button>
              </div>
            </div>
            <div className="border-b-2 pb-3 border-slate-300 grid grid-cols-6 gap-2 text-sm">
              <h1 className="font-mono text-center text-stone-700 text-xl font-bold col-span-6">Send Feedback</h1>
              <textarea value={feedback} onChange={e => setFeedback(e.target.value)} placeholder="Your comments here ... " className="bg-slate-100 text-slate-600 h-28 placeholder:text-slate-600 placeholder:opacity-50 border border-slate-200 col-span-6 resize-none outline-none rounded-lg pt-2 pl-3 duration-300 focus:border-slate-600"></textarea>
              <button onClick={() => setReaction("good")} className="fill-slate-600 col-span-1 flex justify-center items-center rounded-lg p-2 duration-300 bg-slate-100 hover:border-slate-600 focus:fill-blue-200 focus:bg-blue-400 border border-slate-200">
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512">
                  <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm177.6 62.1C192.8 334.5 218.8 352 256 352s63.2-17.5 78.4-33.9c9-9.7 24.2-10.4 33.9-1.4s10.4 24.2 1.4 33.9c-22 23.8-60 49.4-113.6 49.4s-91.7-25.5-113.6-49.4c-9-9.7-8.4-24.9 1.4-33.9s24.9-8.4 33.9 1.4zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"></path>
                </svg>
              </button>
              <button onClick={() => setReaction("bad")} className="fill-slate-600 col-span-1 flex justify-center items-center rounded-lg p-2 duration-300 bg-slate-100 hover:border-slate-600 focus:fill-blue-200 focus:bg-blue-400 border border-slate-200">
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512">
                  <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM174.6 384.1c-4.5 12.5-18.2 18.9-30.7 14.4s-18.9-18.2-14.4-30.7C146.9 319.4 198.9 288 256 288s109.1 31.4 126.6 79.9c4.5 12.5-2 26.2-14.4 30.7s-26.2-2-30.7-14.4C328.2 358.5 297.2 336 256 336s-72.2 22.5-81.4 48.1zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"></path></svg>
              </button>
              <span className="col-span-2"></span>
              <button className="bg-slate-100 stroke-slate-600 border border-slate-200 col-span-2 flex justify-center rounded-lg p-2 duration-300 hover:border-slate-600 hover:text-white focus:stroke-blue-200 focus:bg-blue-400" disabled={buttonDisabled} onClick={() => postfeedback()}>
                <svg fill="none" viewBox="0 0 24 24" height="30px" width="30px" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" d="M7.39999 6.32003L15.89 3.49003C19.7 2.22003 21.77 4.30003 20.51 8.11003L17.68 16.6C15.78 22.31 12.66 22.31 10.76 16.6L9.91999 14.08L7.39999 13.24C1.68999 11.34 1.68999 8.23003 7.39999 6.32003Z"></path>
                  <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" d="M10.11 13.6501L13.69 10.0601"></path>
                </svg>
              </button>

            </div>
            <div className="grid w-full max-w-80">
              <div className="relative">
                <input
                  type="text"
                  className="flex w-full rounded-lg border border-gray-300 bg-gray-50 px-2.5 py-4 text-sm text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  value="Video link"
                  disabled
                  readOnly
                />
                <Clipboard.WithIconText
                  valueToCopy={window.location}
                  className="absolute end-2.5 top-1/2 inline-flex -translate-y-1/2 items-center justify-center rounded-lg border border-gray-200 bg-white px-2.5 py-2 text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700"
                  iconClassName="me-1.5 h-3 w-3"
                  successIconClassName="me-1.5 h-3 w-3 text-blue-700 dark:text-blue-500"
                  labelClassName="inline-flex items-center text-xs font-semibold text-blue-700 dark:text-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  )
}
