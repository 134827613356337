import React from "react";
import { Link } from "react-router-dom";

const BlogList = ({blogs}) => {
  return (
    <>
     {
      blogs && blogs.map((blog,index)=>{
        return (
<div key={index} class="col-lg-4 col-md-6 col-sm-10">
        <div class="blog-grid-item">
          <div class="blog-grid-thumb">
            <Link to={blog.name}>
              <img src={blog.thumbnail_url} alt="blog-img" />
            </Link>
          </div>
          <div class="blog-grid-content">
            <span class="date">{blog.created_at}</span>
            <h2 class="title">
              <Link to={blog.name}>
                {blog.name}
              </Link>
            </h2>
            <p>
      {blog.description}
            </p>
          </div>
        </div>
      </div>
        )
      })
     }
      
      
      
    </>
  );
};

export default BlogList;
