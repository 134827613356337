import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'flowbite-react';

const ChunkCarousel = ({ chunkIndex, setChunkIndex, chunks }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      
      <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Video Editor</h1>
      
      <div className="">
        <Breadcrumb aria-label="Chunk Navigation">
          <div className="flex flex-wrap justify-start">
            {chunks.map((chunk, index) => (
              <BreadcrumbItem key={index}>
                <button
                  onClick={() => setChunkIndex(index)}
                  className={`font-bold text-base ${chunkIndex === index ? 'text-blue-600' : 'text-slate-500'}`}
                >
                  Chunk {index + 1}
                </button>
              </BreadcrumbItem>
            ))}
          </div>
        </Breadcrumb>
      </div>

    </div>
  );
};

export default ChunkCarousel;
