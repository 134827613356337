// src/App.js
import React, { useEffect, useState } from 'react';
// import './index.css';
import Header2 from 'components/header/Header2';
import Footer2 from 'components/footer/footer2';

import ChunkCarousel from './ChunkCarousel';
import EditorBox from './EditorBox';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from 'axiosInstance';
import Layout from 'components/layout/Layout';
import styles from '../ScriptView/styles.module.css'
import { Player } from '@lottiefiles/react-lottie-player';


const Editor = () => {
  const [selectedChunk, setSelectedChunk] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [chunkIndex, setChunkIndex] = useState(0);
  const [videoJson, setVideoJson] = useState();
  const [videoLink, setVideoLink] = useState("");
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(videoJson)
  }, [videoJson])

  const fetchVideoJson = async () => {
    const videoID = params.videoID
    let response = await axiosInstance.get(process.env.REACT_APP_BACKEND_URL + `/getvideojson/${videoID}/`);
    let data = response.data;
    console.log(data);
    setVideoJson(data.videoJson);
    setVideoLink(data.link);
  }
  useEffect(() => {
    fetchVideoJson();
  }, [])

  const submit = async () => {
    const videoID = params.videoID;
    setSubmitted(true);
    window.scrollTo(0, 0);
    let response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + `/rerender/`, { videoJson, videoID });
    if (response.data.suceess == 0) {
      return alert(response.data.message);
    }
    console.log(response.data);
    navigate(`/result/mainservices/${response.data.id}`);
    console.log(response);
  }

  const handleSubmit = () => {
    submit();
  };

  const videoGenerationLoadingLottie = "https://lottie.host/deae5640-9508-420d-ba9f-a18f11cde781/EYovAyAMKT.json";

  return (
    videoJson && (
      <Layout
        headerStyle={2}
        footerStyle={2}
      >
        {submitted ? (
          <div className={submitted ? styles.animation : styles.hidden}>
            <Player className={styles.loadingAnimation}
              src={videoGenerationLoadingLottie}
              loop
              autoplay
            />
            <h1 className={styles.LoadingText}> Hold tight! EditAI is re-creating your video. <br></br>You'll get an email with the link soon!</h1>
          </div>
        ) : (
          <div className="min-h-screen flex flex-col">
            <div>

              <div className="flex-grow p-4">
                <ChunkCarousel chunkIndex={chunkIndex} setChunkIndex={setChunkIndex} chunks={videoJson} />

                {videoJson && (
                  <EditorBox
                    chunkIndex={chunkIndex}
                    videoJson={videoJson}
                    setVideoJson={setVideoJson}
                    videoLink={videoLink}
                  />
                )}
              </div>
              <div className="flex flex-col items-center">
                <button
                  onClick={handleSubmit}
                  className="text-white bg-violet-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-normal text-xl rounded-xl px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </div>
            </div>

          </div>
        )}
      </Layout>
    )
  );
};

export default Editor;
