function validateDuration(file, maxDurationInSeconds = 600) {
    return new Promise((resolve) => {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);
  
      videoElement.onloadedmetadata = () => {
        URL.revokeObjectURL(videoElement.src);
        resolve(videoElement.duration <= maxDurationInSeconds);
      };
    });
  }

  function validateFileSize(file, maxSizeInMB = 250) {
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    return file.size <= maxSizeInBytes;
  }

  function validateFileType(file) {
    const allowedTypes = ['video/mp4', 'video/avi', 'video/mov', 'video/mkv'];
    return allowedTypes.includes(file.type);
  }

  function validateAndCorrectFileName(file, maxLength = 50) {
    let fileName = file.name;
  
  // Replace spaces with underscores
  let sanitizedFileName = fileName.replace(/\s+/g, '_');

  // Sanitize the file name by replacing any other unsafe characters
  sanitizedFileName = sanitizedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
  
    // Ensure the sanitized name is not too long
    if (sanitizedFileName.length > maxLength) {
      // Trim the base name to fit within the allowed length (accounting for file extension)
      const extension = sanitizedFileName.split('.').pop();
      const baseName = sanitizedFileName.slice(0, maxLength - extension.length - 1);
      sanitizedFileName = `${baseName}.${extension}`;
    }
  
    // If the sanitized name is different from the original, return the new name
    if (sanitizedFileName !== fileName) {
      return sanitizedFileName;
    }
  
    // If no changes were needed, return the original name
    return fileName;
  }

exports.validateDuration = validateDuration;
exports.validateFileSize = validateFileSize;
exports.validateFileType = validateFileType;
exports.validateAndCorrectFileName = validateAndCorrectFileName; 