import React from 'react'
import { useState, useEffect } from 'react';
import style from './style.module.css';
import axiosInstance from 'axiosInstance';
import { useNavigate } from 'react-router-dom';
import { driver } from 'driver.js';
import "driver.js/dist/driver.css";
import LoadingBar from 'react-top-loading-bar';
import { FileInput, Label, Checkbox, Button, Spinner, ToggleSwitch } from 'flowbite-react';
import { validateAndCorrectFileName, validateDuration, validateFileSize, validateFileType } from 'utils';


export default function FootageEditingForm(props) {

  const [feVideo, setFeVideo] = useState();
  const [subtitles, setSubTitles] = useState();
  const [noiseReduction, setNoiseReduction] = useState(false);
  const [awkwardSileneceRemoval, setAwkwardSilenceRemoval] = useState(false);
  const [images, setImages] = useState(false);
  const [graphics, setGraphics] = useState(false);
  const [brolls, setBrolls] = useState(false);
  const [mode, setMode] = useState("prompt");
  const [subtitle, setSubtitle] = useState("NOAH");
  // const subtitleList = ['HORMOZI', 'maya', 'NOAH', 'william', 'IMAN', 'BEAST', 'DAVID']
  const subtitleList = ['glowy', 'maya', 'NOAH', 'william', 'IMAN', 'BEAST']
  const [subtitleType, setSubtitleType] = useState('line');
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [videoUploadProgress, setProgress] = useState(0);
  const [videoPreview, setVideoPreview] = useState('');

  const [edits, setEdits] = useState({
    include_graphics:true,
    include_brolls: true,
    include_sfx: true,
  });


  const [backgroundType, setBackGroundType] = useState("1MvnT4DyKDM5I-ere_G9DsFUBlJ0xBxi7");
  const cloudinaryLinks = ['https://assets-editai.azureedge.net/videos/1720163139_grid_bbunak.mp4', 'https://assets-editai.azureedge.net/videos/1716219709_Black_Topography_Lvqmw7_jpkdgr.mp4', 'https://assets-editai.azureedge.net/videos/1716219461_Arcane_Inferno_M12ogz_bgbqcw.mp4', 'https://assets-editai.azureedge.net/videos/1705241131_Red_Flame_mbaarr.mp4', 'https://assets-editai.azureedge.net/videos/1705241132_Black_Paper_Texture_vhnhlx.mp4']
  const backs = ['1OYekmbneyPNbCcTmBfierwjEFvuzoIh-', '1Qh2mrlrGe_ft5G2Toc3JzF9gp6ceCPLb', '1k2whu9cknBLdORrG2doWzRR22DcHQhVU', '1cqe2KoBmI9BePprwa6XC9xvtkoeX_5To', '1zioxvIbSQ80gWLd0TLpe0OWYeVZpE8XN']

  useEffect(() => {
    if (videoUploadProgress == 100) {
      props.setLoaderType("transcription");
      props.setLoader(true);
    }
  }, [videoUploadProgress]);

  const handleChange= (e) => {
    const { name, checked } = e.target;
    setEdits(prevState => ({
        ...prevState,
        [name]: checked
    }));
};

  const footageEditing = async () => {

    if (!validateFileType(feVideo)) {
      alert('Invalid file type. Please upload a video file.');
      return;
    }
  
    if (!validateFileSize(feVideo)) {
      alert('File size exceeds the limit. Please upload a smaller file.');
      return;
    }
  
    const isDurationValid = await validateDuration(feVideo);
    if (!isDurationValid) {
      alert('Video duration exceeds the limit. Please upload a shorter video.');
      return;
    }

    const validFileName=validateAndCorrectFileName(feVideo);
    const correctedFile = new File([feVideo], validFileName, { type: feVideo.type });

    

    setButtonDisabled(true);
    let formdata = new FormData();
    formdata.append('video', correctedFile);
    formdata.append('noise_reduction', noiseReduction);
    formdata.append('background', backgroundType);
    formdata.append('sub_type', props.subType);
    formdata.append('main_type', props.mainType);
    formdata.append('subtitle_type', subtitleType);
    formdata.append('subtitle_name', subtitle);
    formdata.append('orientation', "portrait");
    formdata.append('edits', JSON.stringify(edits));



    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded * 100 / progressEvent.total);
        setProgress(progress);
      }
    }
    try {
      const response = await axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/transcribe/', formdata, config);
      console.log(response.data);
      if (response.data.success == 0) {
        return alert(response.data.message);
      }
      props.setLoader(false);
      navigate(`/transcription/${response.data.id}`);

    }
    catch (e) {
      console.log(e)
      alert("Error uploading video. Please try again.");
    }
    // setButtonDisabled(false);
  }

  
  useEffect(() => {
    if (feVideo) {
      const videoURL = URL.createObjectURL(feVideo);
      setVideoPreview(videoURL);
      return () => URL.revokeObjectURL(videoURL);
    }
  }, [feVideo]);

  useEffect(() => {
    const isTourCompleted = localStorage.getItem('tourCompleted');
    if (!isTourCompleted) {
    const tour = driver({
      showProgress: true,
      steps: [
        {
          element: '#multilayer',
          popover: {

            title: 'Multilayer',
            description: 'Multilayer type video consists of graphics, icons and animations with sound effects.',
            side: 'top'
          }
        },
        {
          element: '#slideshow',
          popover: {

            title: 'Slideshow',
            description: 'Slideshow type video consists of sequence of visually appealing images or videoclips.',
            side: 'top'
          }
        },
        // {
        //   element: '#maya',
        //   popover: {

        //     title: 'Maya Type',
        //     description: 'Maya type video is divided into two distinct sections: the lower half showcases the raw footage, while the upper half is dedicated to the Multilayer or Slideshow elements.',
        //     side: 'left'
        //   }
        // },
        {
          element: '#dhruv',
          popover: {

            title: 'Dhruv Type',
            description: 'Dhruv type video automatically removes the background from the footage, leaving only the speaker visible.',
            side: 'bottom'
          }
        },
        // {
        //   element: '#circle',
        //   popover: {

        //     title: 'Circle Type',
        //     description: 'Circle type video has raw footage in a circular frame at the bottom-right, surrounded by Multilayer/Slideshow elements for visual storytelling.',
        //     side: 'right'
        //   }
        // },
        {
          element: '#choosefile',
          popover: {

            title: 'Choose File',
            description: 'Click here to upload your raw footage video',
            side: 'top'
          }
        },
        {
          element: '#broll',
          popover: {

            title: 'B-Roll',
            description: 'Adds Stock Video Clips between your footage video',
            side: 'right'
          }
        },
        {
          element: '#soundeffect',
          popover: {

            title: 'Sound Effects',
            description: 'Adds Sound Effects to the assets used in your video',
            side: 'right'
          }
        },
        {
          element: '#graphics',
          popover: {

            title: 'Graphics',
            description: 'Adds graphic images on top of your footage video',
            side: 'right'
          }
        },
        {
          element: '#singleword',
          popover: {

            title: 'Subtitle Selection',
            description: 'Single word subtitles will appear as one word on your video',
            side: 'left'
          }
        },
        {
          element: '#singleline',
          popover: {

            title: 'Subtitle Selection',
            description: 'Single line subtitles will appear as one line on your video',
            side: 'right'
          }
        },
        {
          element: '#subtitles',
          popover: {

            title: 'Subtitle',
            description: 'Choose subtitle type for your video',
            side: 'top'
          }
        },
        {
          element: '#submit',
          popover: {

            title: 'Submit',
            description: 'Click and wait to see your awesome creation',
            side: 'top'
          }
        },


      ]

    });

    tour.drive();
    localStorage.setItem('tourCompleted', 'true');

    // return () => {
    //   tour.done();
    // };

}
  },
    [feVideo]);



  return (
    <>
      <div className={style.container}>
        <LoadingBar
          color='#f11946'
          height={4}
          progress={videoUploadProgress}
          onLoaderFinished={() => setProgress(0)} />
        <h1 className='p-2 pt-3'>Video type</h1>
        <div className='flex pt-2'>
          <button
            id="multilayer"
            className={`inline-block rounded border px-10 py-3 text-sm font-medium focus:outline-none focus:ring ${props.mainType === 'multilayer'
              ? 'text-white bg-indigo-600'
              : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            onClick={() => {

              props.setMainType('multilayer');
            }}
          >
            Multilayer
          </button>


          <button
            id="slideshow"
            className={`inline-block rounded border px-10 py-3 text-sm font-medium focus:outline-none ${props.mainType === 'slideshow'
              ? 'text-white bg-indigo-600'
              : 'text-gray-300 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
              }`}
            // onClick={() => {
            //   props.setMainType('slideshow');
            // }}

          >
            Slideshow
          </button>
        </div>

        <div className='flex flex-col items-center justify-center py-2'>
          <h1 className='p-2'>Sub type</h1>

          <div className='flex pt-2'>

            <button
              id="dhruv"
              className={`inline-block rounded border px-10 py-3 text-sm font-medium focus:outline-none focus:ring ${props.subType === 'dhruv'
                ? 'text-white bg-indigo-600'
                : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                }`}
              onClick={() => {
                props.setSubType('dhruv');
              }}


            >
              Dhruv
            </button>

            <button
              id="maya"
              className={`inline-block rounded border px-10 py-3 text-sm font-medium focus:outline-none  ${props.subType === 'maya'
                ? 'text-white bg-indigo-600'
                : 'text-gray-300 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                }`}
            // onClick={() => {
            //   props.setSubType('maya');
            // }}
            >
              Maya
            </button>

            <button
              id="circle"
              className={`inline-block rounded border px-10 py-3 text-sm font-medium focus:outline-none ${props.subType === 'circle'
                ? 'text-white bg-indigo-600'
                : 'text-gray-300 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                }`}
            // onClick={() => {
            //   props.setSubType('circle');
            // }}

            >
              Circle
            </button>
            
          </div>

        </div>

        <div className='flex flex-col items-center justify-center'>
          <h1 className='p-2 pt-3'>Raw video footage</h1>
          <div className="flex w-full items-center justify-center pt-2">
            <Label
              id="choosefile"
              htmlFor="dropzone-file"
              className="dark:hover:bg-bray-800 flex h-52  cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center px-12">

                {feVideo ? <video className={style.uploadedVideo} src={videoPreview}></video> :
                  <svg
                    className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLineJoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>}
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  {feVideo ?
                    <>
                      <span className="font-semibold">{feVideo.name}</span>
                    </>
                    : <span className="font-semibold">Click to upload</span>}
                </p>
                {feVideo ? <></> : <p className="text-xs text-gray-500 dark:text-gray-400">.mp4 format only</p>}
              </div>
              <FileInput id="dropzone-file" className="hidden" onChange={(e) => { setFeVideo(e.target.files[0]) }} />
            </Label>
          </div>

          {/* <div className={style.backgrounds}>

          <div className={style.videoDiv} id="selectbackground">
          <h1 className='p-1.5'>Select background</h1>

            {backs.map((back, index) => (
              <div key={index} className={style.background}>
                <video
                  onClick={() => setBackGroundType(back)}
                  src={cloudinaryLinks[index]}
                  className={backgroundType === back ? style.selected : ''}
                  loop muted autoPlay
                ></video>
              </div>
            ))}
          </div>

        </div> */}

          <div className="flex py-4 flex-col items-center justify-center gap-2">
            <h1 className='p-2 pb-3'>Add &nbsp;layers</h1>
            <div className='flex flex-col md:flex-row items-center justify-center gap-4'>
              <label id="broll" className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  name='include_brolls'
                  className="sr-only peer"
                  checked={edits.include_brolls}
                  onChange={handleChange}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"> B-Roll (stock videos) </span>
              </label>
              <label id="soundeffect" className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  name='include_sfx'
                  className="sr-only peer"
                  checked={edits.include_sfx}
                  onChange={handleChange}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Sound Effects</span>
              </label>
              <label id="graphics" className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  name='include_graphics'
                  className="sr-only peer"
                  checked={edits.include_graphics}
                  onChange={handleChange}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Graphics</span>
              </label>
            </div>
          </div>

          <div className='flex-col items-center justify-center'>
            <h1 className='pt-3 pb-3 text-center'>Subtitle type</h1>

            <button
              id="singleword"
              className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitleType === 'word'
                ? 'text-white bg-indigo-600'
                : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                }`}
              onClick={() => {
                setSubtitleType('word')
              }}
            >
              Single word
            </button>

            <button
              id="singleline"
              className={`inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring ${subtitleType === 'line'
                ? 'text-white bg-indigo-600'
                : 'text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white active:bg-indigo-500'
                }`}
              onClick={() => {
                setSubtitleType('line')

              }}
            >
              Single line
            </button>

          </div>

          <div className='flex-col items-center justify-center'>
            <h1 className='pt-4 pb-3 text-center'>Select subtitle</h1>

            <div id="subtitles" className={style.subtitles}>
              {subtitleList.map((sub) => (
                <video
                  onClick={() => setSubtitle(sub)}
                  src={subtitleType === 'word' ? `https://assets-editai.azureedge.net/videos-subtitles/${sub}_an_portim.mp4` : `assets/demovideos/subtitles/${sub}.mp4`}
                  className={subtitle === sub ? style.selected : 'rounded-lg'}
                  loop muted autoPlay
                ></video>))}
            </div>
          </div>

        </div>

        {!buttonDisabled ?
          <Button type="button" id="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={() => footageEditing()} disabled={buttonDisabled}>
            Submit
          </Button>
          :
          <Button type="button" id="submit" class="text-white bg-purple-500 hover:bg-purple-600 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-6xl px-4 py-2.5 me-2 mb-2 dark:bg-purple-500 dark:hover:bg-purple-600 focus:outline-none dark:focus:ring-purple-600">
            <Spinner color="purple" aria-label="Uploading spinner" size="md" />
            <span className="pl-3">Uploading...</span>
          </Button>
        }

      </div>
    </>
  )
}
