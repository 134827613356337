import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Footer2 = () => {
  return (
    <footer>
      <div className="footer-area-two">
        <div className="footer-top-two">
          <div className="container">
            <div className="footer-features-wrap">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6">
                  <div className="footer-features-item">
                    <div className="icon">
                      <img
                        src="/assets/img/icon/f_features_icon01.svg"
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Create Your Video Now</h4>
                      <Link to="/generatevideo">
                        Start using our AI
                        <i className="fal fa-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="footer-features-item st1">
                    <div className="icon">
                      <img
                        src="/assets/img/icon/f_features_icon02.svg"
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Get Start a Free Trial</h4>
                      <Link to="/signup">
                        Start free our AI
                        <i className="fal fa-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="footer-features-item st2">
                    <div className="icon">
                      <img
                        src="/assets/img/icon/f_features_icon03.svg"
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Join Our Community</h4>
                      <Link to="https://www.instagram.com/editai.in">
                        Join our AI
                        <i className="fal fa-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-widget-wrap">
              <div className="row row-cols-1 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                <div className="col">
                  <div className="footer-widget">
                    <h4 className="fw-title-two">company</h4>
                    
                    <div className="footer-link-two">
                      <ul className="list-wrap">
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        {/* <li>
                          <Link to="/contact">AI Research</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/contact">Jobs</Link>
                        </li>
                        <li>
                          <Link to="/contact">Press Kit</Link>
                        </li> */}
                        <li>
                          <Link to="/contact">Contact Us</Link>
                        </li>
                      </ul>
                      
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-widget st1">
                    <h4 className="fw-title-two">Features</h4>
                    <div className="footer-link-two">
                      <ul className="list-wrap">
                        <li>
                          <Link to="/ai-footage-editing">Footage Editing</Link>
                        </li>
                        <li>
                          <Link to="/ai-footage-editing">Footage Editing (Slideshow)</Link>
                        </li>
                        <li>
                          <Link to="/text-to-video">Text to Video (Multilayer)</Link>
                        </li>
                        <li>
                          <Link to="/text-to-video">Text to Video (slideshow)</Link>
                        </li>
                        <li>
                          <Link to="/microservices/autosubtitles">Auto Subtitle Addition</Link>
                        </li>
                        <li>
                          <Link to="/microservices/backgroundremover">Video Background Removal</Link>
                        </li>
                        <li>
                          <Link to="/microservices/silenceremover">Auto Silence Removal</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-widget st2">
                    <h4 className="fw-title-two">Resources</h4>
                    <div className="footer-link-two">
                      <ul className="list-wrap">
                        <li>
                          <AnchorLink href="#pricing">Pricing</AnchorLink>
                          {/* <Link to="/#pricing" smooth={true} duration={500}>Pricing</Link> */}
                        </li>
                        <li>
                        <Link to="/signup">Free trial</Link>
                          {/* <Link to="/#pricing"  smooth={true} duration={500}>Free Trial</Link> */}
                        </li>
                        {/* <li>
                          <Link to="/contact">Case Studies</Link>
                        </li> */}
                        <li>
                          <AnchorLink href="#demo_videos">Example videos</AnchorLink>
                        </li>
                        <li>
                          <Link to="/tools">Tools</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-widget st3">
                    <h4 className="fw-title-two">Use Cases</h4>
                    <div className="footer-link-two">
                      <ul className="list-wrap">
                        <li>
                          <AnchorLink href="#demo_videos">Explainer Videos</AnchorLink>
                        </li>
                        <li>
                          <AnchorLink href="#demo_videos">How-to Videos</AnchorLink>
                        </li>
                        <li>
                          <AnchorLink href="#demo_videos">Marketing Videos</AnchorLink>
                        </li>
                        <li>
                          <AnchorLink href="#demo_videos">Advertisement Videos</AnchorLink>
                        </li>
                        <li>
                          <AnchorLink href="#demo_videos">Product Updates</AnchorLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-widget st4">
                    <h4 className="fw-title-two">Need Emergency Help?</h4>
                    <div className="footer-contact-two">
                      <Link to="tel:9881210225" className="phone">
                        +(91) 988 121 0225
                      </Link>
                      <Link to="tel:7249094646" className="phone">
                        +(91) 724 909 4646
                      </Link>
                      <Link to="mailto:team@gmail.com" className="email">
                        team@editai.in
                      </Link>
                    </div>
                    <div className="footer-social-two">
                      <ul className="list-wrap">
                        <li>
                          <Link to="https://twitter.com/editai_">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.instagram.com/editai.in">
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.linkedin.com/in/edit-ai">
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://youtube.com/@_editai_">
                            <i className="fab fa-youtube" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="copyright-text">
                  <p>
                    Copyright © {new Date().getFullYear()} &nbsp; Edit AI &nbsp; All rights reserved.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-menu">
                  <ul className="list-wrap">
                    <li>
                      <Link to="/privacypolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/termsandconditions">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/refundpolicy">Refund Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer2;
